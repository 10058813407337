// import React from 'react'
// import styles from './NavigationItem.module.css'
// import { NavLink } from 'react-router-dom'

// const NavigationItem = (props) => (
//     <li className = {styles.NavigationItem}>
//         <NavLink
//             to = {props.link}
//             activeClassName = {styles.active}
//             exact = {props.exact}>
//             {props.children}
//         </NavLink>
//     </li>
// )

// export default NavigationItem

import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styles from './NavigationItem.module.css';
import { useDispatch, useSelector } from "react-redux";

const NavigationItem = (props) => {
    const history = useHistory();
    const location = useLocation();
    const openInvoice = useSelector((state) => state.openInvoice.openInvoice);
    const inventoryData = useSelector((state) => state.inventoryData.InventoryData);

    const dispatch = useDispatch();

    // const handleNavigation = (event) => {
    //     // dispatch({ type: "OPEN_INVOICE", data: false });
    //     console.log("location-->", location, props.link, openInvoice)
    //     if (location.pathname === props.link || props.link === "/logout" || (props.link === "/setting" && !openInvoice)) {
    //         // If already on the same route, do nothing
    //         return;
    //     }

    //     if (location.pathname === "/invoicedata" && openInvoice) {
    //     event.preventDefault(); // Prevent immediate navigation
    //     const userConfirmed = window.confirm("Are you sure you want to change the tab?");
    //     if (userConfirmed) {
    //         history.push(props.link); // Navigate if user confirms
    //     }
    // }
    // };

    const handleNavigation = (event) => {
        const countSlashes = (path) => {
            return (path.match(/\//g) || [])?.length;
        };
        
        // if (location.pathname === props.link || props.link === "/logout" || (props.link === "/setting" && !openInvoice) || (props.link === "/setting" && inventoryData?.length === 0)) {
        // if (location.pathname === props.link || props.link === "/logout" || (props.link === "/setting" && !openInvoice && inventoryData?.length === 0)) {
        if (location.pathname === props.link || props.link === "/logout" || (props.link === "/setting" && !openInvoice && inventoryData?.length === 0 && countSlashes(location.pathname) === 1)) {
            return;
        }

        if (openInvoice || inventoryData?.length !== 0 || countSlashes(location.pathname) > 1) {
        event.preventDefault();
        const userConfirmed = window.confirm("Are you sure you want to change the tab?");
        if (userConfirmed) {
            history.push(props.link);
            dispatch({ type: "OPEN_INVOICE", data: false });
            dispatch({ type: "INVENTORY_DATA", data: [] });
        }
    }
    };


    return (
        <li className={styles.NavigationItem}>
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName = {styles.active}
                onClick={handleNavigation}
            >
                {props.children}
            </NavLink>
        </li>
    );
};

export default NavigationItem;