import axios from "axios";
import { store } from "..";
// import { store } from "..";

// function getStoreSetting() {
//   const state = store.storeSetting.getState();
//   return state.isMargin;
// }

// const isMargin = getStoreSetting();

const Axios = axios.create({
  headers: {
    "Content-Type": "application/json",
    "store": localStorage.getItem("store"),
    // "margin":isMargin
    // "pos_api": localStorage.getItem("posApi"),
    // "pos_access_token": localStorage.getItem("posAccessToken"),
  },
});

Axios.interceptors.request.use(async function (config) {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const vendorDetails = localStorage.getItem("vendorDetails");
  const posApi = localStorage.getItem("posApi");
  const posAccessToken = localStorage.getItem("posAccessToken");
const storedSetting = localStorage.getItem("Setting")
  config.headers["user-key"] = userId;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["vendordetails"] = vendorDetails;
  config.headers["pos_api"] = posApi;
  config.headers["pos_access_token"] = posAccessToken;
  config.headers["settings"] = storedSetting;

  return config;
});

export default Axios;

const WordpressAxios = axios.create({
  baseURL: "https://maharajafarmersmarketusa.com/wp-json/wc/v2",
  auth: {
    username: "ck_e08ee57c7e2b0e7c10a2c932f2ea24cc4264055f",
    password: "cs_1ae97918f989d6af747fd703d4ce77e821c8b738",
  },
});

export { WordpressAxios };

const POSAxios = axios.create({
  // baseURL: "https://dataservices.sypramsoftware.com/api",
  auth: {
    UserId: "MeCHHkZ9",
    Password: "tdypsA =",
    Pin: "lqBZghxJgaVE",
  },
  headers: {
    Pin: "lqBZghxJgaVE",
    UserId: "MeCHHkZ9",
    Password: "tdypsA =",
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});

export { POSAxios };
