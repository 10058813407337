import { POSAxios, WordpressAxios } from "./axios";
import Axios from "./axios";
import axios from "axios";
var cancelTokenSource = axios.CancelToken.source();

const appendURL = process.env.NODE_ENV === "production" ? "/server" : "";

export class InventoryService {
  async GetProductDetails(productSKU) {
    const response = await WordpressAxios.get(`/products/`, {
      params: { sku: productSKU },
    });
    return response.data;
  }

  async UpdateProductDetails(productId, data) {
    const res = await WordpressAxios.put(`/products/${productId}`, data);
    return res.data;
  }
  async getAllProducts() {
    const res = await WordpressAxios.get("products");
    return res.data;
  }
  async createProduct(data) {
    const res = await WordpressAxios.post("products", data);
    return res.data;
  }

  async GetPOSProductDetails(upc, itemName = "") {
    const response = await Axios.get(appendURL + "/api/getPOSProduct", {
      params: { upc, itemName },
    });
    return response.data;
  }
  async UpdatePOSProducts(data) {
    try {
      console.log(data);
      const res = await Axios.post(
        appendURL + "/api/pos/Product/ManageItem",
        data,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      return res.data.result;
    } catch (error) {
      console.log("error : ", error)
      return error;
    }
  }
  async getVendorlistFromPOS() {
    console.log("getVendorlistFromPOS");
    const res = await Axios.get(
      appendURL + "/api/pos/Product/vendorlist_from_pos"
    );
    return res.data;
  }
  async SyncInventory() {
    const res = await Axios.get(appendURL + "/api/sync");
    console.log(res.data);
    return res.data;
  }
  async UpdateProductFields(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/product/update",
      data
    );
    return res.data;
  }

  // added by Deepanshu
  async UpdateHandWrittenProductFields(data) {
    const res = await Axios.put(
      appendURL + "/api/handwritteninvoice/product/update",
      data
    );
    return res.data;
  }


  //Added by ANURAG 

  async deleteInvoice(data) {
    console.log("object", data)
    const res = await Axios.post(
      appendURL + "/api/invoice/deleteInvoice",
      data
    );
    console.log("res-->", res);
    return res.data;
  }

  async deleteInvoice_product(data) {
    console.log("deleteRow", data)
    const res = await Axios.post(
      appendURL + "/api/invoice/deleteProduct",
      data
    );
    return res.data;
  }


  async previousProductDetails(data) {
    console.log("productCompare", data)
    const res = await Axios.put(
      appendURL + "/api/invoice/previousDetailOfProduct",
      data
    );

    return res.data;
  }



  async CreateNotFoundItems(data) {
    /**
     * data format
     * {Item:String,Description:String,Quantity:String,Price:String,sku:String,Barcode:String,PosSKU:String,InvoiceName:String}
     */
    const res = await Axios.post(appendURL + "/api/invoice/notfound", data);
    return res.data;
  }

  async CreateScanInvoiceData(data) {
    //console.log("IN INVENTORY SERVICE");
    //console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/scaninvoicedata",
      data
    );
    return res.data;
  }
  async UpdateInvoiceData(invoiceName, invoiceNo, date, itemNo, ProductId) {
    console.log(invoiceName);
    console.log(invoiceNo);
    console.log(itemNo);
    console.log(date);
    const res = await Axios.post(appendURL + "/api/invoice/updateinvoicedata", {
      params: {
        invoiceName: invoiceName,
        invoiceNo: invoiceNo,
        date: date,
        itemNo: itemNo,
        ProductId: ProductId
      },
    });
    return res.data;
  }

  // async updateSaveInvoiceStatus(obj) {
  //   console.log('obj : ', obj);
  //   const res = await Axios.post(appendURL + "/api/invoice/updatesaveinvociestatus", {
  //     params: obj,
  //   });
  //   return res.data;
  // }

  async updateSaveInvoiceStatus(obj) {
    console.log("Updating invoice status with:", obj);

    try {
      const res = await Axios.post(appendURL + "/api/invoice/updatesaveinvociestatus", {
        params: obj,
      });
      return res.data;  // Make sure you're returning the actual response
    } catch (error) {
      console.error("Error updating invoice status:", error);
      return { success: false, error: error.message };  // Return a failed response in case of error
    }
  }

  async UpdateDBafterPosUpdate(data) {
    try {
      console.log(data);
      const res = await Axios.post(
        appendURL + "/api/invoice/updatedbafterposupdate",
        data,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      console.log(res);
    } catch (error) {
      console.log("error : ", error)
      return error;
    }
  }

  async saveDetails(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/savedetails", data);
    return res.data;
  }

  async reversePOSUpdate(invoiceName, invoiceNo, date, itemNo, ProductId) {
    console.log(invoiceName);
    console.log(invoiceNo);
    console.log(itemNo);
    console.log(date);
    const res = await Axios.post(appendURL + "/api/invoice/reverseposupdate", {
      params: {
        invoiceName: invoiceName,
        invoiceNo: invoiceNo,
        date: date,
        itemNo: itemNo,
        ProductId: ProductId
      },
    });
    return res.data;
  }

  async gethandwrittenPosLogs(data) {
    console.log("data : ", data);

    const res = await Axios.get(
      appendURL + "/api/invoice/gethandwrittenposlogs",
      {
        params: {
          invoicename: data.invoicename,
          itemNo: data.itemNo,
          sku: data.sku,
          updatedate: data.updatedate,
        },
      }
    );
    return res.data;
  }

  async linkingCorrect(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/linkingcorrect",
      data
    );
    return res;
  }

  // added by Deepanshu
  async linkingCorrectInScaninvoice(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/linkingCorrectInScaninvoice",
      data
    );
    return res;
  }

  async saveDetailsInScaninvoice(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/saveDetailsInScaninvoice",
      data
    );
    return res.data;
  }

  async linkManually(data) {
    console.log(data);

    try {
      const res = await Axios.post(appendURL + "/api/invoice/linkmanually", data, {
        cancelToken: cancelTokenSource.token
      });
      console.log(">> res : ", res)

      return res;
    } catch (error) {
      console.log("error : ", error)
      return error;

    }

  }

  // async linkManually(data) {
  //   console.log(data);

  //   const res = await Axios.post(appendURL + "/api/invoice/linkmanually", data);
  //   return res;
  // }

  async getHicksvilleData(value) {
    console.log("in inventory for hicks data");
    const res = await Axios.get(appendURL + "/api/invoice/gethicksvilledata", {
      params: { input: value },
    });
    return res.data;
  }

  async getHicksvilleDataNew() {
    console.log("in inventory for hicks data");
    const res = await Axios.get(appendURL + "/api/invoice/gethicksvilledata", {
      params: { input: "" },
    });
    return res.data;
  }

  async getSavedInvoices(data) {
    console.log("in inventory for saved invoices");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getsavedinvoices", {
      params: data,
    });
    return res.data;
  }

  //excel data
  async getSavedDocuments(data) {
    console.log("in inventory for saved document");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getDoc", {
      params: data,
    });
    return res.data;
  }

  async getNewInvoiceStatus(data) {
    console.log("data : ", data);

    const res = await Axios.get(appendURL + "/api/invoice/getnewinvoicestatus");
    return res.data;
  }

  async getAllSavedInvoices(data) {
    console.log("in inventory for saved invoices");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getallsavedinvoices", {
      params: data,
    });
    return res.data;
  }

  async UpdateDBProduct(data) {
    console.log(data);
    const res = await Axios.put(appendURL + "/api/invoice/pos/update", data);
    return res.data;
  }
  async CreateDBProduct(data) {
    const res = await Axios.post(appendURL + "/api/invoice/pos/create", data);
    return res.data;
  }

  async getInitialSyncedData(dateObj) {
    console.log("In inventory service");
    console.log(dateObj);
    const res = await Axios.get(appendURL + "/api/invoice/pos", dateObj);
    console.log(res);
    return res.data;
  }

  async generateLog(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/generatelog", data);
    return res;
  }

  async linkManuallyLog(data) {
    console.log("inventory logmanually", data);
    try {


      const res = await Axios.post(
        appendURL + "/api/invoice/linkmanuallylog",
        data
        , {
          cancelToken: cancelTokenSource.token
        });
      console.log(">> linkManuallyLog: ", res)
      return res;
    } catch (error) {
      console.log(">> linkManuallyLog: error ", error)
      return error;

    }
  }


  // async linkManuallyLog(data) {
  //   console.log(data);
  //   const res = await Axios.post(
  //     appendURL + "/api/invoice/linkmanuallylog",
  //     data
  //   );
  //   return res;
  // }

  // added by Deepanshu
  async getLinkingLogsXlsx(data) {
    console.log(data, "hello world");
    const res = await Axios({
      url: appendURL + "/api/invoice/getlinkinglogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  // added by Deepanshu
  async getPosLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/getposLogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async reverseUpdate(data) {
    console.log(data);

    const res = await Axios.post(
      appendURL + "/api/invoice/reverseupdate",
      data
    );
    return res.data;
  }

  async UnidentifiedLog(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/unidentifiedlog",
      data
    );
    return res;
  }

  async handwrittenPosLogs(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/handwrittenposlogs",
      data
    );
    return res;
  }
  async GethandwrittenLogs(invName) {
    const data = {
      invoiceName: invName,
    };
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittenlogs", {
      params: data,
    });
    return res;
  }

  async fetchProductFromPosList(data) {
    console.log(data);
    const res = await Axios.get(
      appendURL + "/api/invoice/fetchproductfromposlist",
      data
    );
    return res.data;
  }

  async posLogs(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/poslogs", data);
    return res.data;
  }

  async getItemForHandwrittenInvoice(data) {
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/getitemhandwritten", {
      params: data,
    });
    return res.data;
  }
  async UpdateInvoiceDetails(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updateinvoicedetails",
      data
    );
    return res.data;
  }
  async UpdateVerifyDetails(data) {

    const res = await Axios.put(
      appendURL + "/api/invoice/updateverifydetails",
      data
    );
    return res.data;
  }
  async linkingCorrectTrue(data) {
    console.log(data);
    const res = await Axios.post(appendURL + "/api/invoice/linkingcorrecttrue", data);
    return res;
  }

  async InsertAllProducts(data) {

    try {
      // data.hasOwnProperty("ProductId") && delete data.ProductId;

      console.log("<><> InsertAllProducts: ", data);
      const res = await Axios.post(appendURL + "/api/allproducts", data, {
        cancelToken: cancelTokenSource.token
      });
      console.log(">> InsertAllProducts : ", res)
      return res.data;
    } catch (error) {
      console.log(">> InsertAllProducts : error ", error)
      return error;

    }
  }

  // async InsertAllProducts(data) {
  //   console.log(data);
  //   const res = await Axios.post(appendURL + "/api/allproducts",data);
  //   return res.data;
  // }
  async getAllProductsDataByUsername(data) {
    // console.log(barcode);
    const res = await Axios.get(appendURL + "/api/getallproductsdatabyusername", {
      params: data,
    });
    return res.data;
  }

  async getInvoicesPDF(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/generateInvoicesPDF",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }


  // Function for handwritten
  async getHandwrittenSavedInvoices(data) {
    console.log("in inventory for saved invoices");
    console.log(data);
    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittensavedinvoices", {
      params: data,
    });
    return res.data;
  }

  async CreateSaveHandwrittenInvoiceData(data) {
    //console.log("IN INVENTORY SERVICE");
    //console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/savehandwritteninvoicedata",
      data
    );
    return res.data;
  }

  // added by Deepanshu
  async UpdateHandWrittenProductFields(data) {
    const res = await Axios.put(
      appendURL + "/api/handwritteninvoice/product/update",
      data
    );
    return res.data;
  }

  async UpdateSaveHandwrittenInvoiceData(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatesavehandwritteninvoicedata",
      data
    );
    return res.data;
  }

  async UpdateHandwrittenInvoiceDetails(data) {
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatehandwritteninvoicedetails",
      data
    );
    return res.data;
  }

  async saveDetailsInSaveHandwritten(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/saveDetailsInScaninvoice",
      data
    );
    return res.data;
  }

  async handwrittenLinkingLogs(data) {
    console.log(data);
    const res = await Axios.post(
      appendURL + "/api/invoice/handwrittenlinkinglogs",
      data
    );
    return res;
  }


  async linkManuallyHandwritten(data) {
    console.log(data);

    const res = await Axios.post(appendURL + "/api/invoice/linkmanuallyhandwritten", data);
    return res;
  }
  async syncProductsWithPos() {
    // console.log(data);
    const res = await Axios.get(appendURL + "/api/syncproductwithpos");
    return res.data;
  }

  async getHandwrittenLinkingLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/gethandwrittenlinkinglogs",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async getHandwrittenPosLogsXlsx(data) {
    console.log(data);
    const res = await Axios({
      url: appendURL + "/api/invoice/gethandwrittenposLogsxlsx",
      headers: { "Contant-Type": "application/json" },
      params: data,
      method: "GET",
      responseType: "blob",
    });
    return res;
  }

  async getHandwrittenNewInvoiceStatus(data) {
    console.log("data : ", data);

    const res = await Axios.get(appendURL + "/api/invoice/gethandwrittennewinvoicestatus");
    return res.data;
  }

  async updateHandwrittenSaveInvoiceStatus(obj) {
    console.log('obj : ', obj);
    const res = await Axios.post(appendURL + "/api/invoice/updatehandwrittensaveinvociestatus", {
      params: obj,
    });
    return res.data;
  }

  async getProductFromServer(barcode) {
    try {
      console.log('obj : ', barcode);
      const res = await Axios.post(appendURL + "/api/getproductfromserver", {
        params: barcode,
      },
        {
          cancelToken: cancelTokenSource.token
        });
      console.log("getProductFromServer res : ", res.data)
      if (Object.keys(res.data).length) return res.data;
      else {
        return { data: "Product Not Found" }
      }
    } catch (error) {
      console.log("error : ", error)
      return {
        data: "Product Not Found"
      };
    }
  }

  // added by Deepanshu
  async stockUpdatedInPosInScaninvoice(data) {
    try {
      console.log(data);
      const res = await Axios.post(
        appendURL + "/api/invoice/stockUpdatedInPosInScaninvoice",
        data,
        {
          cancelToken: cancelTokenSource.token
        }
      );
      return res;
    } catch (error) {
      return error;

    }
  }

  async UpdateSaveInvoiceData(data) {
    console.log("UpdateSaveInvoiceData-->", data);
    // data.InvoiceData.hasOwnProperty("ProductId") && delete data.InvoiceData.ProductId;
    /**
     ***** data format
     * data = {invoiceName: "chetak", itemName:"CAS 123", value:{"Description": "jnckwc", "Price": "44"}}
     */
    //  console.log(data);
    const res = await Axios.put(
      appendURL + "/api/invoice/updatesaveinvoicedata",
      data
    );
    return res.data;
  }
  async getAllProductsDataByDateRange(data) {
    // console.log(barcode);
    const res = await Axios.post(appendURL + "/api/getallproductsdatabydaterange", data);
    return res.data;
  }
  async getallproductsdataforstock(data) {
    // console.log(barcode);
    const res = await Axios.post(appendURL + "/api/getallproductsdataforstock", data);
    return res.data;
  }
  async getPosSoldData(data) {
    // console.log(barcode);
    const res = await Axios.post(appendURL + "/api/getPosSoldData", data);
    return res.data;
  }
  async productverified(data) {

    const res = await Axios.put(
      appendURL + "/api/invoice/productverified",
      data
    );
    return res.data;
  }

  async cancelRequest() {
    cancelTokenSource.cancel('Request canceled by user');
    // setTimeout(() => {
    // cancelTokenSource = axios.CancelToken.source();
    // }, 3000);
    return "request canceled"
  }
  isAxiosTokenEexpired() {
    let token = cancelTokenSource.token
    console.log("getAxiosToken : ", token, token.hasOwnProperty("reason"))
    if (token.hasOwnProperty("reason") && token.reason.Cancel == 'Request canceled by user') {
      console.log("getAxiosToken : ", token.reason)
      return true
    } else {
      return false
    }
  }
  createAxiosToken() {
    cancelTokenSource = axios.CancelToken.source();
    return "token created"
  }

  async GetCredentails() {
    try {
      const response = await Axios.post(appendURL + "/api/getCredentials");
      return response.data;
    } catch (error) {
      return error;
    }
  };

  async GetLinkedData(data) {
    try {
      const res = await Axios.put(appendURL + "/api/invoice/linkingsuggestion/", data,
        {
          cancelToken: cancelTokenSource.token
        });
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async ExtraLinkingData(data) {
    try {
      const res = await Axios.put(
        appendURL + "/api/invoice/alignItems",
        data
      );
      // return res.data;
      return res;
    } catch (error) {
      return error;
    }
  };

  async GetInvoiceMetaData(data) {
    try {
      const res = await Axios.put(appendURL + "/api/invoice/invoiceMetaData/", data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async updateVendorListToPOS() {
    try {
      // const res = await Axios.post(appendURL + "/api/invoice/updatedVendorListFromPOS/");
      const res = await Axios.post(appendURL + "/api/invoice/updatedVendorListFromPOS/", { invoiceList: JSON.parse(localStorage.getItem("invoiceList")) });
      return res.data.result;
    } catch (error) {
      return error;
    }
  };

  async removeLinkingCollection(data) {
    try {
      const res = await Axios.put(appendURL + "/api/invoice/removeLinkedItem/", data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async GetFireStoreData(data, icmsName) {
    try {
      // const response = await Axios.put(appendURL + "/api/firestore", {url: data});
      const response = await Axios.put(appendURL + "/api/firestore", { url: data, icmsName });
      return response.data;
    } catch (error) {
      return error;
    }
  };



  async connectDatabase(data) {
    try {
      const res = await Axios.post(appendURL + "/api/invoice/connectDatabase/", data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async linkManuallyAll(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/linkManuallyAll", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async posSync(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/sync_with_pos/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async posCostMoveICMS(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/pos_cost_move_icms/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async quantityAndCostUpdate(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/quantity_and_cost_update/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async quantityUpdate(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/quantity_update/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async costUpdate(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/cost_update/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async createDataNew(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/create_data/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async removeLinking(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/api/invoice/removeLinking/", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async handleSaveDoc(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/pushDocUpload", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };
  async handleDeleteDoc(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/deleteDoc", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async handleGetDoc(data) {
    try {
      console.log("data-->", data)
      const res = await Axios.put(appendURL + "/getDoc", data);
      console.log("res-->", res)
      return res.data;
    } catch (error) {
      return error;
    }
  };

  async moveLinkingCollection(data) {
    try {
      const res = await Axios.put(appendURL + "/api/linkingcollectiontransfer/", data);
      return res.data;
    } catch (error) {
      return error;
    }
  };

 

}
