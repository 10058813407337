import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationItems from '../NavigationItems/NavigationItems';
import styles from './Toolbar.module.css';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import { InventoryService } from '../../../services/InventoryService';
import firebase from '../../../firebase';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLocation } from 'react-router-dom';
import { authLogout } from '../../../store/action/actionAuth';
import { Tooltip } from '@material-ui/core';


const Toolbar = () => {
    const isAuthenticated = useSelector(state => state.auth.userId);
    const db = firebase.firestore();
    const inventoryService = new InventoryService();

    // const credentialsData = useSelector((state) => state.credentialsData.credentialsLoaderData);
    const userEmail = useSelector((state) => state.userDetails.userEmail);
    const [storeName, setStoreName] = useState(localStorage.getItem("store"));
    const [documentNames, setDocumentNames] = useState([]);
    const [isComponentOpen, setIsComponentOpen] = useState(false);
    const [selectedStore, setSelectedStore] = useState([]);
    console.log("selectedStore", selectedStore)
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        // getDocumentName();
        setStoreName(localStorage.getItem("store"));
    }, []);

    const handleIconClick = () => {
        setIsComponentOpen(!isComponentOpen);
    };
    // let loaderStart = true

    useEffect(async () => {
        console.log("userEmailToolbar", userEmail, location);
        userEmail !== "" && await getDocumentNamess();

    }, [userEmail]);

    const loaderFun = async () => {
        try {
            // console.log("loaderFun : ",loaderStart)
            // if (loaderStart) {
                // loaderStart = false
                const res1 = await inventoryService.getHicksvilleDataNew();
                console.log("getHicksville_res1", res1)

                const namesString = res1.map(obj => obj.name).join('\n');
                // console.log("getHicksvilleDataNew", namesString)

                localStorage.setItem("hicksvilleString", namesString);
                // loaderStart = true

            // }

        } catch (error) {
            console.log("error", error)
            // loaderStart = true

        }
    }

    const debouce = function (fn, d) {
      console.log("debouce : ")
      let timer;
      return function (event, value, index) {
        // let context = this,
        // args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn(event, value, index);
        }, d);
      };
    };
  
    const loaderFun_debounce = debouce(loaderFun, 2000);
    const handleSync = async () => {
        const currentTime = new Date().toLocaleTimeString();
        try {
            if (window.confirm('Do you want to proceed further?')) {
                await db.collection('syncProductsNew')
                    .doc(localStorage.getItem("store"))
                    .set({ loader: true, error: false, workdone: false, email: userEmail, time: currentTime, closeButton: false });
                console.log('Document successfully written!');

                const result = await inventoryService.syncProductsWithPos();
                console.log('syncProductsWithPos : ', result);


                if (result.success === false) {
                    await db.collection('syncProductsNew')
                        .doc(localStorage.getItem("store"))
                        .set({ loader: false, error: true, workdone: false, email: userEmail, time: currentTime, closeButton: false });
                    console.log('Document successfully not written!');
                } else if (result.success === true) {
                    await db.collection('syncProductsNew')
                        .doc(localStorage.getItem("store"))
                        .set({ loader: false, error: false, workdone: true, email: userEmail, time: currentTime, closeButton: false });
                    console.log('Document successfully written!');
                }
            }
        } catch (err) {
            await db.collection('syncProductsNew')
                .doc(localStorage.getItem("store"))
                .set({ loader: false, error: err, workdone: false, email: userEmail, time: currentTime, closeButton: false });
            console.log('Error Occurred');
            alert("Products Sync API is not working");
        }
    };


    const getDocumentNamess = async () => {
        console.log("getDocumentNamess-->", userEmail);

        if (userEmail !== "") {

            try {
                db.collection("store_access").doc(userEmail).onSnapshot((snapshot) => {
                    // console.log("name-->", snapshot.exists, snapshot.data()?.stores);
                    if (snapshot.exists && snapshot.data() !== undefined && snapshot.data()?.stores.length > 0) {
                        // console.log("case-->1", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                        setDocumentNames(snapshot.data().stores);
                        let data = snapshot.data()?.stores;
                        let userStore = data.filter((item) => item === localStorage.getItem("store"));
                        setSelectedStore(userStore)
                        // console.log("userStore-->", userStore);
                        if (userStore.length === 0 && localStorage.getItem("store")) {
                            console.log("case-->2", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                            alert("You have not access to this store. Please contact to admin.");
                            dispatch(authLogout());
                        }
                        console.log("getDocumentName-->", snapshot.data()?.stores, documentNames, location.pathname);
                    } else {
                        console.log("case-->3", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                        console.log("getDocumentName-->1", snapshot.data()?.stores, documentNames, location.pathname);
                        setDocumentNames([]);

                        alert("You have not access to any store. Please contact to admin.");
                        (!snapshot.exists || localStorage.getItem("store")) && dispatch(authLogout());

                    }
                });

            } catch (error) {
                console.error("Error retrieving document names:", error);
                throw error;
            }
        }

    }

    const getDocumentNamesss = async () => {
        console.log("getDocumentNamess-->", userEmail);

        if (userEmail !== "") {
            try {
                db.collection("store_access").doc(userEmail).onSnapshot((snapshot) => {
                    //   console.log("case-->1", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                    if (snapshot.exists && snapshot.data() !== undefined && snapshot.data()?.stores.length > 0) {
                        //   console.log("case-->2", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                        let data = snapshot.data()?.stores;
                        let userStore = data.filter((item) => item === localStorage.getItem("store"));
                        //   console.log("userStore-->", userStore);
                        if (userStore.length === 0 && localStorage.getItem("store")) {
                            // console.log("case-->3", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                            alert("You have not access to this store. Please contact to admin.");
                            dispatch(authLogout());
                        }
                    } else {
                        console.log("case-->4", snapshot.exists, snapshot.data(), localStorage.getItem("store"));
                        console.log("getDocumentName11-->1", snapshot.data()?.stores);
                        alert("You have not access to any store. Please contact to admin.");
                        dispatch(authLogout());
                        localStorage.getItem("store") && dispatch(authLogout());
                        (!snapshot.exists || localStorage.getItem("store")) && dispatch(authLogout());
                    }
                });

            } catch (error) {
                console.error("Error retrieving document names:", error);
                throw error;
            }
        }

    }


    const showCondition = (localStorage.getItem("store") && JSON.parse(localStorage.getItem("invoiceList")) && JSON.parse(localStorage.getItem("optionData")) && localStorage.getItem("posApi") && localStorage.getItem("posAccessToken")) ? true : false;

    return (
        <header className={styles.Toolbar}>
            <nav className={styles.DesktopOnly}>
                <NavigationItems isAuth={isAuthenticated} selectedStore={selectedStore} loaderFun={loaderFun_debounce} />
            </nav>
            {isAuthenticated && (
                <>
                    {showCondition && <nav className={styles.sync}>
                        <div style={{ width: "30px", display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                            <Tooltip title={"Product Sync"} placement="top" style={{ backgroundColor: "#fff", color: "black" }}>
                                <SyncIcon onClick={handleSync} style={{
                                    fontSize: "25px",
                                    color: "#fff",
                                    cursur: "pointer"
                                }} />
                            </Tooltip>
                        </div>


                    </nav>}
                    <nav className={styles.sync}>
                        <div>
                            <Autocomplete
                                value={localStorage.getItem("store")}
                                onChange={async (event, newValue) => {
                                    if (newValue) {
                                        if (window.confirm("Do you want to change the store?")) {
                                            localStorage.setItem("store", newValue);
                                            setStoreName(localStorage.getItem("store"));
                                            localStorage.removeItem("vendorDetails");
                                            localStorage.removeItem("invoiceList");
                                            localStorage.removeItem("optionData");
                                            localStorage.removeItem("posApi");
                                            localStorage.removeItem("posAccessToken");
                                            localStorage.removeItem("hicksvilleString");

                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 200);
                                        }
                                    }
                                }}
                                options={documentNames}
                                getOptionLabel={(option) => option}
                                style={{ width: 250 }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <input
                                            style={{
                                                width: "180px",
                                                padding: "5px",
                                                borderRadius: "27px",
                                                background: "#ebebeb",
                                                border: "1px solid #cacaca",
                                                fontSize: "14px",
                                                outline: "none",

                                            }}
                                            type="text"
                                            {...params.inputProps}
                                            placeholder="Select Store"
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    </nav>


                </>
            )}
        </header>
    );
};

export default Toolbar;
