import React from 'react';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import styles from './Layout.module.css';
import { useSelector } from 'react-redux';

const Layout = (props) => {
    const isAuthenticated = useSelector(state => state.auth.userId);

    return (
        <>

            <div className={styles.ScreenSize}>
                <div className={styles.toolbarhgt}>

                    {isAuthenticated && (
                        <Toolbar />
                    )}

                </div>
                <div className={styles.mainHgt}>
                    <main className={styles.main}>
                        {props.children}
                    </main>
                </div>
            </div>
        </>
    );
};

export default Layout;



// import React, { Component } from 'react'
// import Toolbar from '../Navigation/Toolbar/Toolbar'
// import styles from './Layout.module.css'
// import { useSelector } from 'react-redux';

// class Layout extends Component {

//     render() {
//         const isAuthenticated = useSelector(state => state.auth.userId);
//         return (
//             <div className={styles.ScreenSize}>
//                 <div className={styles.toolbarhgt}>

//                     <Toolbar />
//                 </div>
//                 <div className={styles.mainHgt}>

//                     <main className={styles.main}>
//                         {this.props.children}
//                     </main>
//                 </div>

//             </div>
//         )
//     }
// }

// export default Layout;
