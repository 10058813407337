import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import firebase from "../../firebase";
import { InventoryService } from "../../services/InventoryService";
import { useHistory } from 'react-router-dom';
import "./regexdata.css";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../UI/Spinner/Spinner";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
            height: "75vh"
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%',
        maxWidth: '800px',
        borderRadius: '10px'
    },
    modalHeader: {
        marginBottom: theme.spacing(2),
        fontSize: '18px',
        background: 'whitesmoke',
        color: 'darkblue',
        padding: "10px"

    },
    modalContent: {
        marginBottom: theme.spacing(2),
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    updateButton: {
        marginRight: theme.spacing(2),
    },
    AddVendor: {

    },
    addButton: {
        marginLeft: theme.spacing(2),
        position: 'sticky',
        top: 70
    },
    stickyContainer: {
        position: 'fixed',
        bottom: 8,
        left: 350,
        zIndex: 5000,
        color: 'white',
        padding: theme.spacing(1),
        backdropFilter: 'blur(0px) saturate(144%)',
        WebkitBackdropFilter: 'blur(0px) saturate(144%)',
        backgroundColor: '#174545',
        borderRadius: '8px',
    },
    stickyHeader: {
        position: 'sticky',
        top: 1,
        backgroundColor: theme.palette.background.paper,
        zIndex: 999,
        border: "darkblue"
    },

}));

const RegexData = () => {
    const dispatch = useDispatch();
    const inventoryService = new InventoryService();
    const history = useHistory();
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedVendorName, setSelectedVendorName] = useState(null);
    const [updatedVendorName, setUpdatedVendorName] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [updatedVendorData, setUpdatedVendorData] = useState(null);
    console.log("myupdated vendor", updatedVendorData)
    const [optionData, setOptionData] = useState({});
    const [commonStore, setCommonStore] = useState("commonStore");
    console.log("optionData", optionData.length)
    const [newVendorAdded, setNewVendorAdded] = useState({});

    const [addVendorDetail, setAddVendorDetail] = useState({
        vendorName: '',
        regex: '',
        header: '',
        body: '',
        notAllowed: '',
        emptyColumn: '',

    });

    const [invList, setInvList] = useState([])
    console.log("invList", invList)
    const [errors, setErrors] = useState({});


    const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState(false);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await inventoryService.GetCredentails();
                const optionDataRef = storageRef.child(`${commonStore}/optionData.json`);
                let url_optionData = await optionDataRef.getDownloadURL();
                const res_optionData = await inventoryService.GetFireStoreData(url_optionData, "commonStore");
                console.log("res_optionData", res_optionData)
                setOptionData(res_optionData);

                const invoicedataRef = storageRef.child(`${commonStore}/invoiceList.json`);
                let url_invoiceList = await invoicedataRef.getDownloadURL();
                const res_invoiceList = await inventoryService.GetFireStoreData(url_invoiceList, "commonStore");
                setInvList(res_invoiceList)
            } catch (error) {
                console.log("error : ", error);
                alert(error);
            }
        };

        fetchData();
    }, []);

    const handleOpen = (vendor, vendorName) => {
        setSelectedVendor(vendor);
        setSelectedVendorName(vendorName);
        setUpdatedVendorName(vendorName);
        setUpdatedVendorData(vendor);
        setOpen(true);
    };

    const openAddVendorModal = () => {
        setIsAddVendorModalOpen(true);
        setIsEditMode(true)
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVendor(null);
        setSelectedVendorName(null);
        setUpdatedVendorName(null);
        setIsEditMode(false);
    };

    const closeAddVendorModal = () => {
        setIsAddVendorModalOpen(false);

    };

    const handleVendorDataChange = (e) => {
        const { name, value } = e.target;
        console.log("values", name, value)
        if (name === "vendorName") {
            if (/\s|-/.test(value)) {
                toast.error("Vendor name should not contain spaces or dashes.", { autoClose: 2000, position: toast.POSITION.TOP_CENTER });
                return;
            }
            setUpdatedVendorName(value);
        } else {
            setUpdatedVendorData(prev => {
                return {
                    ...prev,
                    [name]: value,
                }
            });
        }
    };


    const handleAddNewVendor = (event) => {
        const { name, value } = event.target;
        setAddVendorDetail((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };


    const handleSubmitNewvendor = async () => {
        try {
            let AddedNewVendor = { ...optionData };
            const newlist = [...invList];

            if (!addVendorDetail.vendorName || /\s|[-]/.test(addVendorDetail.vendorName)) {
                throw new Error("Vendor name should not contain spaces or dashes.");
            }

            if (addVendorDetail?.header && typeof addVendorDetail?.header === 'string') {
                addVendorDetail.header = addVendorDetail.header.split(',').map(item => item.trim());
            }

            if (addVendorDetail?.body && typeof addVendorDetail?.body === 'string') {
                let vendorBody = addVendorDetail?.body.toString();
                const selectString = vendorBody.replace(/'/g, '"').replace(/\s*,\s*/g, ',');
                let body = [];
                let subbody = [];
                let index = 0;

                selectString.split(",").forEach((e, i) => {
                    const regex1 = new RegExp(/^(\[0)$/);
                    const regex2 = new RegExp(/(\$('|")])$/);

                    if (regex1.test(e)) {
                        index = i;
                    }

                    if (!regex1.test(e) && !regex2.test(e)) {
                        body.push(isNaN(e) ? e.trim() : Number(e.trim()));
                    } else {
                        subbody.push(e);
                    }
                });

                if (subbody.length > 0) {
                    let jsonString = subbody.join().replace(/'/g, '"');
                    try {
                        body.splice(index, 0, JSON.parse(jsonString));
                    } catch (error) {
                        throw new Error("Error parsing body JSON string. Please ensure the format is correct.");
                    }
                }

                addVendorDetail.body = body;
            }

            AddedNewVendor[addVendorDetail.vendorName] = { ...addVendorDetail };

            const newVendor = {
                value: addVendorDetail.vendorName,
                slug: addVendorDetail.vendorName,
                jsonName: `${addVendorDetail.vendorName}.json`,
                emptyColumn: addVendorDetail.emptyColumn,
                databaseName: addVendorDetail.vendorName,
            };

            newlist.push(newVendor);

            const res = await inventoryService.GetCredentails();
            if (!res || !commonStore) {
                throw new Error("Error fetching credentials. Please check your network connection.");
            }

            try {
                const optionDataRef = storageRef.child(`${commonStore}/optionData.json`);
                const updatedOptionDataBlob = new Blob([JSON.stringify(AddedNewVendor)], { type: 'application/json' });
                await optionDataRef.put(updatedOptionDataBlob);
            } catch (error) {
                throw new Error("Error saving optionData to Firebase Storage. Please check your Firebase configuration.");
            }

            try {
                const invoiceListRef = storageRef.child(`${commonStore}/invoiceList.json`);
                const updatedInvoiceListBlob = new Blob([JSON.stringify(newlist)], { type: 'application/json' });
                await invoiceListRef.put(updatedInvoiceListBlob);
            } catch (error) {
                throw new Error("Error saving invoiceList to Firebase Storage. Please check your Firebase configuration.");
            }

            setOptionData(AddedNewVendor);
            setInvList(newlist);

            toast.success("Data successfully updated", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });

            setIsAddVendorModalOpen(false);
            setAddVendorDetail({
                vendorName: '',
                regex: '',
                header: '',
                body: '',
                notAllowed: '',
                emptyColumn: '',
            });

        } catch (error) {
            console.error("Error:", error.message);
            toast.error(error.message || "Error updating data", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
            dispatch({ type: "API_LOADER" });
        }
    };



    const handleUpdate = async () => {
        try {
            console.log("function called update")

            if (/\s|-/.test(updatedVendorName)) {
                toast.error("Vendor name should not contain spaces or dashes.", { autoClose: 2000, position: toast.POSITION.TOP_CENTER });
                return;
            }

            // Ensure no fields are left empty
            for (const [key, value] of Object.entries(updatedVendorData)) {
                if (!value) {
                    toast.error(`${key} cannot be empty.`, { autoClose: 2000, position: toast.POSITION.TOP_CENTER });
                    return;
                }
            }

            // Create a new store object from the existing optionData
            const updatedStore = { ...optionData };

            // Process header field
            if (updatedVendorData?.header && typeof updatedVendorData?.header === 'string') {
                updatedVendorData.header = updatedVendorData.header.split(',').map(item => item.trim());
            }

            // Process body field
            if (selectedVendor?.body && typeof updatedVendorData?.body === 'string') {
                let vendorBody = (updatedVendorData?.body)?.toString();
                // let vendorBody = (selectedVendor?.body)?.toString();
                console.log("vendorBody1", vendorBody)
                const selectString = vendorBody.replace(/'/g, '"').replace(/\s*,\s*/g, ',');
                console.log("selectString", selectString)
                let body = [];
                let subbody = [];
                let index = 0;
                console.log("mybody1", body)

                console.log("selectString : ", selectString, selectedVendor, updatedVendorData)

                selectString.split(",").forEach((e, i) => {
                    const regex1 = new RegExp(/^(\[0)$/);
                    const regex2 = new RegExp(/(\$('|")])$/);

                    if (regex1.test(e)) {
                        index = i;
                    }

                    if (!regex1.test(e) && !regex2.test(e)) {
                        body.push(isNaN(e) ? e.trim() : Number(e.trim()));
                    } else {
                        subbody.push(e);
                    }
                });

                if (subbody.length > 0) {
                    body.splice(index, 0, JSON.parse(subbody.join().replace(/'/g, '"')));
                }
                console.log("mybody2", body)
                updatedVendorData.body = body;
            }

            // Handle vendorName change
            if (updatedVendorName !== selectedVendorName) {
                delete updatedStore[selectedVendorName];
            }

            console.log("updatedVendorData : ", updatedVendorData)
            updatedStore[updatedVendorName] = updatedVendorData;


            // Upload updated optionData to Firebase Storage
            const res = await inventoryService.GetCredentails();
            const optionDataRef = storageRef.child(`${commonStore}/optionData.json`);
            const updatedDataBlob = new Blob([JSON.stringify(updatedStore)], { type: 'application/json' });

            await optionDataRef.put(updatedDataBlob);

            // Update state and close modal
            setOptionData(updatedStore);

            toast.success("Data successfully updated", { autoClose: 1000, position: toast.POSITION.TOP_CENTER });
            setIsEditMode(false);
            setOpen(false);
            setSelectedVendor(null);
            setSelectedVendorName(null);
            setUpdatedVendorName(null);
            setIsAddVendorModalOpen(false);

        } catch (error) {
            console.error("Error updating data in Firebase Storage: ", error);
            toast.error("Error updating data", { autoClose: 2000, position: toast.POSITION.TOP_CENTER });
            dispatch({ type: "API_LOADER" });
        }
    };

    const validateFields = () => {
        let validationErrors = {};
        if (!addVendorDetail.vendorName.trim()) {
            validationErrors.vendorName = "Vendor Name is required.";
        } else {
            // Replace hyphens and spaces with underscores and validate
            const sanitizedVendorName = addVendorDetail.vendorName.replace(/[-\s]/g, "_");
            if (sanitizedVendorName !== addVendorDetail.vendorName) {
                validationErrors.vendorName = "Vendor Name cannot contain '-' or spaces. Use '_' instead.";
                setAddVendorDetail(prevData => ({
                    ...prevData,
                    vendorName: sanitizedVendorName,
                }));
            }
        }

        if (!addVendorDetail.regex.trim()) validationErrors.regex = "Regex is required.";
        if (!addVendorDetail.header.trim()) validationErrors.header = "Header is required.";
        if (!addVendorDetail.body.trim()) validationErrors.body = "Body is required.";
        if (!addVendorDetail.notAllowed.trim()) validationErrors.notAllowed = "Not Allowed value is required.";
        if (!addVendorDetail.emptyColumn.trim()) validationErrors.emptyColumn = "Empty Column value is required.";
        // if (addVendorDetail.emptyColumn !== (true && false)) validationErrors.emptyColumn = "EmptyColumn value must be true or false.";


        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const formatBody = (bodyArray) => {
        return bodyArray?.map(item => {
            if (Array.isArray(item)) {
                return `[${item.map(subItem =>
                    typeof subItem === 'string' ? `'${subItem}'` : subItem
                ).join(', ')}]`;
            }
            return item;
        }).join(', ');
    };
    return (
        <>
         <ToastContainer />
            <div className="MainWrapper">

                <div className="wrapper">
                    {!optionData || Object.keys(optionData).length === 0 ? (
                        <Spinner />
                    ) : (
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className={classes.stickyHeader}>
                                <tr className="HeaderRegex">
                                    <th>S.No.</th>
                                    <th>Vendor</th>
                                    <th>Regex</th>
                                    <th>Header</th>
                                    <th>Body</th>
                                    <th>Not Allowed</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(optionData).map((vendorName, index) => {
                                    const vendor = optionData[vendorName];
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{vendorName}</td>
                                            <td>{vendor.regex}</td>
                                            <td>{Array.isArray(vendor.header) ? vendor.header.join(', ') : ''}</td>
                                            <td>{formatBody(vendor.body)}</td>
                                            <td>{vendor.notAllowed}</td>
                                            <td>
                                                <button
                                                    onClick={() => handleOpen(vendor, vendorName)}
                                                    style={{
                                                        backgroundColor: "#4CAF50",
                                                        border: "none",
                                                        color: "white",
                                                        padding: "12px 20px",
                                                        fontSize: "12px",
                                                        margin: "2px",
                                                        cursor: "pointer",
                                                        borderRadius: "10px"
                                                    }}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <center id="transition-modal-title" className={classes.modalHeader}>Update Option Data</center>
                                {selectedVendor && (
                                    <div className={classes.modalContent}>
                                        {!isEditMode ? (
                                            <>
                                                <p><strong className="vendorTitle">Vendor:</strong> <span className="modalValue">{selectedVendorName}</span></p>
                                                <p><strong className="vendorTitle">Regex:</strong>  <span className="modalValue">{selectedVendor?.regex}</span></p>
                                                <p><strong className="vendorTitle">Header:</strong> <span className="modalValue"> {Array.isArray(selectedVendor?.header) ? selectedVendor.header.join(', ') : ''}</span></p>
                                                <p><strong className="vendorTitle">Body:</strong>  <span className="modalValue">{Array.isArray(selectedVendor?.body) ? selectedVendor.body.join(', ') : ''}</span></p>
                                                <p><strong className="vendorTitle">Not Allowed:</strong> <span className="modalValue"> {selectedVendor?.notAllowed}</span></p>
                                                <p><strong className="vendorTitle">Empty Column:</strong> <span className="modalValue"> {selectedVendor?.emptyColumn}</span></p>

                                                <div className={classes.modalActions}>
                                                    <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)} className={classes.updateButton}>
                                                        Edit
                                                    </Button>
                                                    <Button variant="contained" color="secondary" onClick={handleClose}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <TextField
                                                    label="Vendor Name"
                                                    name="vendorName"
                                                    value={updatedVendorName}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled
                                                />
                                                <TextField
                                                    label="Regex"
                                                    name="regex"
                                                    value={updatedVendorData.regex}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    label="Header"
                                                    name="header"
                                                    value={updatedVendorData.header}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    label="Body"
                                                    name="body"
                                                    value={updatedVendorData.body}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    label="Not Allowed"
                                                    name="notAllowed"
                                                    value={updatedVendorData.notAllowed}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    label="Enter Empty Column value..."
                                                    name="emptyColumn"
                                                    value={updatedVendorData.emptyColumn}
                                                    onChange={handleVendorDataChange}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />


                                                <div className={classes.modalActions}>
                                                    <Button variant="contained" color="primary" onClick={handleUpdate} className={classes.updateButton}>
                                                        apply
                                                    </Button>
                                                    <Button variant="contained" onClick={handleClose}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={isAddVendorModalOpen}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={isAddVendorModalOpen}>
                            <div className={classes.paper}>
                                <center id="transition-modal-title" className={classes.modalHeader}>ADD NEW VENDOR</center>
                                <div className={classes.modalContent}>
                                    {(addVendorDetail.vendorName && addVendorDetail.regex && addVendorDetail.body && addVendorDetail.notAllowed && addVendorDetail.emptyColumn).length > 0 ? <center style={{ color: "white", fontSize: "14px", background: "green", padding: "10px" }}>Ready To Add Vendor</center> : <span style={{ color: "red", fontSize: "12px" }}>All fields are required *</span>}
                                    <>
                                        <TextField
                                            label="Enter Vendor Name..."
                                            name="vendorName"
                                            value={addVendorDetail.vendorName}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.vendorName}
                                            helperText={errors.vendorName}
                                        />
                                        <TextField
                                            label="Enter Regex..."
                                            name="regex"
                                            value={addVendorDetail.regex}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.regex}
                                            helperText={errors.regex}
                                        />
                                        <TextField
                                            label="Enter Header..."
                                            name="header"
                                            value={addVendorDetail.header}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.header}
                                            helperText={errors.header}
                                        />
                                        <TextField
                                            label="Enter Body..."
                                            name="body"
                                            value={addVendorDetail.body}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.body}
                                            helperText={errors.body}
                                        />
                                        <TextField
                                            label="Enter Not Allowed value..."
                                            name="notAllowed"
                                            value={addVendorDetail.notAllowed}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.notAllowed}
                                            helperText={errors.notAllowed}
                                        />
                                        <TextField
                                            label="Enter Empty Column value..."
                                            name="emptyColumn"
                                            value={addVendorDetail.emptyColumn}
                                            onChange={handleAddNewVendor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            error={!!errors.emptyColumn}
                                            helperText={errors.emptyColumn}
                                        />
                                        <div className={classes.modalActions}>
                                            <Button variant="contained" color="primary" onClick={handleSubmitNewvendor} className={classes.updateButton}>
                                                Add Vendor
                                            </Button>

                                            <Button variant="contained" color="secondary" onClick={closeAddVendorModal}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </>

                                </div>

                            </div>
                        </Fade>
                    </Modal>

                    {optionData && Object.keys(optionData).length > 0 && (
                        <Button variant="contained" className={classes.stickyContainer} onClick={() => { /* Add Vendor Modal Logic */ }}>
                            Add Vendor
                        </Button>
                    )}

                </div>

                <div className="AddBtn">
                    {optionData && Object.keys(optionData).length > 0 ?
                        <Button variant="contained" className={classes.stickyContainer} onClick={openAddVendorModal} >
                            Add Vendor
                        </Button> : ""

                    }
                </div>

            </div>
        </>
    );
};

export default RegexData;

