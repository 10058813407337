import React, { useState } from 'react';
import './createIcms.css';
import firebase from "../../firebase";
import Axios from '../../services/axios';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const CreateICMS = () => {
  const [createIcms, setCreateIcms] = useState({
    icms_name: '',
    API_SECRET: 'e3zlQxHOJRzcmLdqu18sg1Ytq2XOSJ6X/fErkhjO', 
    FIRESTORE_DOC_ID: 'ecU4ZpHT7mgsaMv1lPLf',              
    MONGO_IP: '192.168.68.136',                             
    POS_ACCESS_TOKEN: '',
    POS_API: '',
    url: 'https://l7c93kk4vj.execute-api.us-east-1.amazonaws.com/default', 
  });
  
  const [errors, setErrors] = useState({});
  console.log("fomr validation errors",errors)
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateIcms({ ...createIcms, [name]: value });
    setErrors((prev) => ({ ...prev, [name]: '' }));  // Clear error on input change
  };

  const validate = () => {
    const newErrors = {};
    const allkeys = Object.keys(createIcms);
    console.log("myallkeys=====>", allkeys)

    Object.keys(createIcms).forEach((field) => {
      if (!createIcms[field]) {
        newErrors[field] = `${field.replace('_', ' ')} is required`;
      }
    });
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    console.log("validation errors", validationErrors)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    //when input validation is done then chnage button name from submit to submiting ----------------------------------
    setIsSubmitting(true);
    const collectionRef = db.collection('icms_cred');
    const dbName = createIcms.icms_name;

    try {
      const docRef = collectionRef.doc(dbName);
      const docSnapshot = await docRef.get();
      if (!docSnapshot.exists) {
        await docRef.set({
          icms_name: createIcms.icms_name,
          API_SECRET: createIcms.API_SECRET,
          FIRESTORE_DOC_ID: createIcms.FIRESTORE_DOC_ID,
        });

        const response = await createICMS(dbName);
        const res = response?.data?.data;
        setSuccessMessage(`Database named '${dbName}' created successfully!`);
      } else {
        setSuccessMessage(`ICMS named ${dbName} already exists.`);
      }
      await docRef.update({
        MONGO_IP: createIcms.MONGO_IP,
        POS_ACCESS_TOKEN: createIcms.POS_ACCESS_TOKEN,
        POS_API: createIcms.POS_API,
        url: createIcms.url,
      });

      setCreateIcms("")

      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    } catch (error) {
      setSuccessMessage('Error occurred while creating the ICMS.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const createICMS = async (dbName) => {
    try {
      const response = await Axios.post("/createDatabase", { dbName });
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className='createIcmsWrapper'>
      <h2 className="form-heading">CREATE ICMS</h2>

      <div className="form-container">
        <form className="styled-form" onSubmit={handleSubmit}>
          <div className="form-grid">
            {['icms_name', 'API_SECRET', 'FIRESTORE_DOC_ID', 'MONGO_IP', 'POS_ACCESS_TOKEN', 'POS_API', 'url'].map((field) => (
              <div key={field} className="form-group">
                <label>
                  {field.replace('_', ' ').toUpperCase()} <span className="required">*</span>  {errors[field] && <span className="error-message">{errors[field]}</span>}
                </label>
                <input
                  className="input-field"
                  type="text"
                  name={field}
                  value={createIcms[field]}
                  onChange={handleChange}
                  placeholder={`Enter ${field.replace('_', ' ')}`}
                  disabled={['API_SECRET', 'FIRESTORE_DOC_ID', 'MONGO_IP', 'url'].includes(field)} 
                />

              </div>
            ))}
          </div>
          <button className="submit-btn-small" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>

        {successMessage && (
          <div
            className={`success-message ${successMessage.toLowerCase().includes('error') ||
              successMessage.toLowerCase().includes('already exists') ? 'error' : 'success'}`}
          >
            {successMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateICMS;
