import React from "react";
import styles from "./Spinner.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "../Button";
import { InventoryService } from "../../services/InventoryService";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "whitesmoke"
  },
}));


const Spinner = (props) => {
  const classes = useStyles();
  const loadersData = useSelector((state) => state.loadersData.loaderData);
  const dispatch = useDispatch();
  const inventoryService = new InventoryService();
  
  console.log("Spinner loadersData : ", loadersData)

  return (
    <>
      <div>
        <Backdrop
          className={classes.backdrop}
          open={true}
          style={{display: "flex",flexDirection: "column"}}
        >
          <div className={styles.boxContainer}>
            <div className={styles.box1}></div>
            <div className={styles.box2}></div>
          </div>
          <h1 id="loaderText" style={{ color: "black", fontSize: "16px" }}>{loadersData ? `[${loadersData.current_iterate}/${loadersData.total_length}] ${loadersData.item_name}` : null}</h1>
          {loadersData ?
            <div>
              <Button
                text="Cancel"
                color="btn btn-danger m-1"
                type="submit"
                onClick={async() => {
                  //   dispatch({
                  //   type: "CANCEL_LOOP",
                  //   payload: {
                  //     cancel_loop:true,
                  //   },
                  // })
                  localStorage.setItem("CANCEL_LOOP", true)
                  // const result = await inventoryService.cancelRequest();
                  // console.log(result);
                }}
              />
              <Button
                text="Skip product"
                color="btn btn-info m-1"
                type="submit"
                onClick={async () => {
                  //    dispatch({
                  //   type: "SKIP_ITERATION",
                  //   payload: {
                  //     skip_iteration:true,
                  //   },
                  // })
                  localStorage.setItem("SKIP_ITERATION", true)
                  // localStorage.setItem("CANCEL_LOOP", true)
                  const result = await inventoryService.cancelRequest();
                  console.log(result);

                }}
              />
            </div>
            : null}
        </Backdrop>
      </div>
    </>
  );
};

export default Spinner;
