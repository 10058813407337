import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import firebase from "../../firebase";
import { InventoryService } from "../../services/InventoryService";
import { Redirect, useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./UserAccess.css";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { authLogout } from "../../store/action/actionAuth";
import { useSelector } from "react-redux";

const UserAccess = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    tableContainer: {
      maxHeight: "92vh",
      overflowY: "auto",
    },
    table: {
      minWidth: 650,
    },
    container: {
      maxHeight: "80vh",
      marginTop: 10,
    },
    table: {
      "& thead th": {
        fontWeight: "600",
        fontSize: 15,
        backgroundColor: "#174545",
        height: "20px",
        color: "white",
        border: "1px solid white",
      },
      "& tbody td": {
        fontWeight: "300",
        border: "1px solid #1745545",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
        cursor: "pointer",
      },
    },
    stickyHeader: {
      position: "sticky",
      top: 17,
      backgroundColor: "#fff",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const classes = useStyles();
  const db = firebase.firestore();
  const [allUsers, setAllUsers] = useState([]);
  // const [storeList, setStoreList] = useState([]);
  const [obj, setObj] = useState({});

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.userDetails.userEmail);
  const [isAuthUser, setAuthUser] = useState("No");

  useEffect(async () => {
    await getUserList();
    await getDocumentName();
  }, []);

  const getUserList = () => {
    let tempObj = {};

    db.collection("store_access")
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          db.collection("store_access")
            .doc(doc.id)
            .onSnapshot((snapshot) => {
              tempObj = {
                ...tempObj,
                [doc.id]: {
                  stores: snapshot.data()?.stores,
                  auth_user: snapshot.data()?.auth_user,
                },
              };
              setObj(tempObj);
            });
        });
      })
      .catch((error) => {
        console.error("Error retrieving document names:", error);
        throw error;
      });
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const getDocumentName = async () => {
    try {
      const documentName = ["All"];
      const snapshot = await db.collection("icms_cred").get();
      snapshot.forEach((doc) => {
        documentName.push(doc.id);
      });
      setDocumentNames(documentName);
    } catch (error) {
      console.error("Error retrieving document names:", error);
      throw error;
    }
  };

  const handleChange = (event, key, authUser1) => {
    console.log("handleChange-->", key, event.target.value);

    let selectedStore = event.target.value;

    let requiredStore = selectedStore.filter((item) => item === "All");

    console.log(
      "requiredStore-->",
      selectedStore,
      requiredStore,
      documentNames
    );

    if (requiredStore[0] === "All") {
      let tempObj = {};
      let allStoreName = documentNames.filter((item) => item !== "All");
      tempObj = {
        ...obj,
        [key]: { stores: allStoreName, auth_user: authUser1 },
      };
      setObj(tempObj);
    } else {
      let tempObj = {};
      tempObj = {
        ...obj,
        [key]: { stores: event.target.value, auth_user: authUser1 },
      };
      setObj(tempObj);
    }
  };

  const authUserHandleChange = (event, key, storess) => {
    let tempObj = {};
    tempObj = {
      ...obj,
      [key]: { stores: storess, auth_user: event.target.value },
    };
    setObj(tempObj);
  };

  const updateStore = async (userEmail, storeNames, authUser) => {
    console.log("updateStore-->", userEmail, storeNames);
    db.collection("store_access")
      .doc(userEmail)
      .set({ stores: storeNames, auth_user: authUser });
    alert("Updated successfully");
  };

  return (
    <>
      <div className="UserAccessWrapper">
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            className={`${classes.table} user-access-table`}
            aria-label="user access table"
          >
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                <TableCell className="table-cell">User Email</TableCell>
                <TableCell className="table-cell">Store Name</TableCell>
                <TableCell className="table-cell">Operation</TableCell>
                <TableCell className="table-cell">Auth User</TableCell>
                <TableCell className="table-cell">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(obj).map(([key, item]) => (
                <TableRow key={key} className="table-row">
                  <TableCell className="table-cell">
                    {userEmail !== key ? key : key + " (You)"}
                  </TableCell>
                  <TableCell className="table-cell cellDesign">
                    {item["stores"]?.length > 0 && item["stores"].join(", ")}
                  </TableCell>
                  <TableCell className="table-cell">
                    <FormControl className="form-control">
                      {/* <InputLabel className="storeName">Store Name</InputLabel> */}
                      <Select
                        multiple
                        value={item["stores"] || []}
                        onChange={(event) =>
                          handleChange(event, key, item["auth_user"])
                        }
                        input={<Input disableUnderline />}
                        renderValue={(selected) => (
                          <div className="chip-container">
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                className="chip"
                              />
                            ))}
                          </div>
                        )}
                      >
                        {documentNames.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className="table-cell AuthuserDesign">
                    <FormControl className="form-control">
                      <InputLabel>Auth User</InputLabel>
                      <Select
                        value={item["auth_user"]}
                        onChange={(event) =>
                          authUserHandleChange(event, key, item["stores"])
                        }
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className="table-cell">
                    <button
                      className="update-btn"
                      onClick={() =>
                        updateStore(key, item["stores"], item["auth_user"])
                      }
                    >
                      Update
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default UserAccess;

{
  /* <h4 className="form-heading">User Access Control Page</h4> */
}

{
  /* <TableContainer component={Paper}  style={{ margin: "10px 0", width: '100%', height: '80%', overflowY: 'auto' }}>
        <Table className={classes.table} size="large" aria-label="a dense table" style={{ width: '100%', height: '100%' }}>
          <TableHead style={{ backgroundColor: '#dddddd' }} className="CompareDialougeHead">
            <TableRow className="CompareDialougeHead">
              <TableCell className="CompareDialougeHeader">User Email</TableCell>
              <TableCell className="CompareDialougeHeader">Store Name</TableCell>
              <TableCell className="CompareDialougeHeader">Operation</TableCell>
              <TableCell className="CompareDialougeHeader">Auth User</TableCell>
              <TableCell className="CompareDialougeHeader">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(obj).map(([key, item]) => (
              <TableRow>
                <TableCell onClick={() => console.log(key, item)}>{(userEmail !== key) ? key : (key + " " + "(You)")}</TableCell>
                <TableCell>{(item["stores"] !== undefined && item["stores"]?.length > 0) ? (item["stores"] + ",") : null}</TableCell>
                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">Store Name</InputLabel>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={(item["stores"] !== undefined || item["stores"]?.length > 0) ? item["stores"] : []}
                      // onChange={handleChange}
                      onChange={(event) => handleChange(event, key, item["auth_user"])}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        console.log("selected", selected),
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            console.log("value-->", value),
                            <Chip key={value} label={value} className={classes.chip} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {documentNames.map((name) => (
                        <MenuItem key={name} value={name}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>

                <TableCell>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Auth User</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                   
                      value={item["auth_user"]}
                      onChange={(event) => authUserHandleChange(event, key, item["stores"])}
                    >
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>

                <TableCell>
                  <button onClick={() => updateStore(key, item["stores"], item["auth_user"])}>Update</button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */
}
