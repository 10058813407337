// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItems_NavigationItems__24BlX {\r\n    margin: 0;\r\n    padding: 0;\r\n    list-style: none;\r\n    display: flex;\r\n    flex-flow: column;\r\n    align-items: center;\r\n    height: 65px;\r\n}\r\n\r\n.NavigationItems_logo__3qf3y{\r\n    height: 60px;\r\n    margin: 0 10px;\r\n}\r\n\r\n@media (min-width: 500px) {\r\n    .NavigationItems_NavigationItems__24BlX {\r\n        flex-flow: row;\r\n    }\r\n}\r\n\r\n\r\n  ", "",{"version":3,"sources":["webpack://src/components/Navigation/NavigationItems/NavigationItems.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".NavigationItems {\r\n    margin: 0;\r\n    padding: 0;\r\n    list-style: none;\r\n    display: flex;\r\n    flex-flow: column;\r\n    align-items: center;\r\n    height: 65px;\r\n}\r\n\r\n.logo{\r\n    height: 60px;\r\n    margin: 0 10px;\r\n}\r\n\r\n@media (min-width: 500px) {\r\n    .NavigationItems {\r\n        flex-flow: row;\r\n    }\r\n}\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItems": "NavigationItems_NavigationItems__24BlX",
	"logo": "NavigationItems_logo__3qf3y"
};
export default ___CSS_LOADER_EXPORT___;
