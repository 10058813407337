import React, { useEffect, useState } from 'react';
import './marginlist.css';
import Axios from '../../services/axios';

const MarginList = () => {
    const [margins, setMargins] = useState([]);
    const [form, setForm] = useState({ department: '', margin: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [editId, setEditId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [fadeOut, setFadeOut] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const getAllMarginlist = async () => {
        try {
            const response = await Axios.get("/getAllMarginList");
            console.log("mylist", response)
            setMargins(response?.data?.allMarginLists?.reverse());
        } catch (error) {
            console.error("Error in getting margin list:", error);
        }
    };

    useEffect(() => {
        getAllMarginlist();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEditing) {
            try {
                const response = await editAllMarginlist(editId, form.department, form.margin);
                const updatedMargins = margins?.map((item, index) =>
                    index === editIndex ? response.data : item
                );

                setMargins(updatedMargins);
                setSuccessMessage(response.message);
                setFadeOut(false);
                setIsEditing(false);
                setEditIndex(null);
                setEditId(null);
            } catch (error) {
                console.error("Error while updating margin:", error);
            }
        } else {
            try {
                const response = await createMarginlist(form.department, form.margin);
                const res = response?.data?.data;
                setMargins([res, ...margins]);
                setSuccessMessage(response?.data?.message);
                setFadeOut(false);
            } catch (error) {
                console.error("Error while creating margin:", error);
            }
        }

        setForm({ department: '', margin: '' });
    };

    const handleEdit = (index) => {
        setForm(margins[index]);
        setIsEditing(true);
        setEditIndex(index);
        setEditId(margins[index]._id);
    };

    const handleDelete = async (id) => {
        console.log("my delete id", id);
        try {
            const response = await Axios.delete(`/deleteMarginList/${id}`);
            setMargins(response?.data?.result);
            setSuccessMessage(response?.data?.message);
            setFadeOut(false);
        } catch (error) {
            console.error("Error in deleting margin list:", error);
            setSuccessMessage(error.message);
            setFadeOut(false);
        }
    };

    const editAllMarginlist = async (id, department, margin) => {
        try {
            const response = await Axios.put("/marginListModify", { id, department, margin });
            return response?.data;
        } catch (error) {
            console.error("Error in updating margin list:", error);
            throw error;
        }
    };


    const createMarginlist = async (department, margin) => {
        try {
            const response = await Axios.post("/createmarginList", { department, margin });
            console.log("function created", response);
            return response;
        } catch (error) {
            console.error("Error in creating margin list:", error);
            throw error;
        }
    };

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setFadeOut(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    useEffect(() => {
        if (fadeOut) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setFadeOut(false);
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [fadeOut]);

    return (
        <>
            <h2 className='marginTitle'>Margin List</h2>
            <div className='marginWrapper'>

                {successMessage && (
                    <div className={`success-message ${fadeOut ? 'fade-out' : ''}`}>
                        {successMessage}
                    </div>
                )}

                <div className="margin-list-container">

                    <form onSubmit={handleSubmit} className="margin-form">
                        <div className='marginInputs'>
                            <div className="form-group">
                                <label htmlFor="department">Department</label>
                                <input
                                    type="text"
                                    id="department"
                                    name="department"
                                    value={form.department}
                                    onChange={handleChange}
                                    placeholder="Enter Department"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="margin">Margin</label>
                                <input
                                    type="text"
                                    id="margin"
                                    name="margin"
                                    value={form.margin}
                                    onChange={handleChange}
                                    placeholder="Enter Margin"
                                    required
                                />
                            </div>
                        </div>

                        <button type="submit" className="submit-btn">
                            {isEditing ? 'Update Entry' : 'Add New'}
                        </button>
                    </form>
                </div>
                <ul className="margin-list">
                    {margins.length > 0 &&
                        margins?.map((item, index) => (
                            <li key={index} className="margin-item">
                                <span>
                                    <strong>Department:</strong> {item.department}
                                </span>
                                <span>
                                    <strong>Margin:</strong> {item.margin}
                                </span>
                                <button onClick={() => handleEdit(index)} className="edit-btn">
                                    Edit
                                </button>
                                <button onClick={() => handleDelete(item._id)} className="delete-btn">
                                    Delete
                                </button>
                            </li>
                        ))}
                </ul>
            </div>
        </>
    );
};

export default MarginList;
