// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__3prwf {\r\n    height: 80vh;\r\n}\r\n.Layout_ScreenSize__2ss1B{\r\nmin-height: 90vh;\r\n}\r\n\r\n.Layout_toolbarhgt__1s7rI{\r\n    height: 7.5vh;\r\n}\r\n.Layout_mainHgt__30sbu{\r\n    height: 80vh;\r\n\r\n} \r\n\r\n/* remove margin from inventory dAta , Stock Update, and Stock Update */", "",{"version":3,"sources":["webpack://src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;AACA,gBAAgB;AAChB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,YAAY;;AAEhB;;AAEA,uEAAuE","sourcesContent":[".main {\r\n    height: 80vh;\r\n}\r\n.ScreenSize{\r\nmin-height: 90vh;\r\n}\r\n\r\n.toolbarhgt{\r\n    height: 7.5vh;\r\n}\r\n.mainHgt{\r\n    height: 80vh;\r\n\r\n} \r\n\r\n/* remove margin from inventory dAta , Stock Update, and Stock Update */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__3prwf",
	"ScreenSize": "Layout_ScreenSize__2ss1B",
	"toolbarhgt": "Layout_toolbarhgt__1s7rI",
	"mainHgt": "Layout_mainHgt__30sbu"
};
export default ___CSS_LOADER_EXPORT___;
