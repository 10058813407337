import Axios from "./axios";
const appendURL = process.env.NODE_ENV === "production" ? "/server" : "";
export class TesseractService {

    async PostImage(image, vendorName, filetype) {
        const data = new FormData();
        console.log("data : ", data, filetype)
        data.append("file", image, `${vendorName},${filetype}`);
        // data.append("file", vendorName);
        // data.append("file", filetype);
        console.log("data2 : ", data)
        var res = await Axios.post(appendURL + `/api/upload-image`, data);
        console.log("data3 :", res)
        return res.data
    }

    async UploadDoc(doc, vendorName, filetype, invoiceNumber) {
        const data = new FormData();
        console.log("data :>> ", doc, vendorName, filetype, invoiceNumber)
        console.log("data : ", data)


        data.append("file", doc, `${vendorName},${invoiceNumber}`, filetype);
        // data.append("file", vendorName);
        // data.append("file", filetype);
        console.log("UploadDoc data : ", data)
        var res = await Axios.post(appendURL + `/api/excel-upload-node`, data);
        console.log("data3 :", res)
        return res.data
    }

    // async GetProductDetails(invoiceName) {
    //     console.log("invoiceName : ", invoiceName);
    //     try {
    //         var res = await Axios.get(appendURL + `/api/product/`, { params: { invoiceName: invoiceName } });
    //         return res.data;
    //     } catch (error) {
    //         return error;
    //     }
    // }

    async GetProductDetails(data, storename) {
        console.log("invoiceName : ", data);
        let headers = {}
        if (storename) headers.store = storename;
        var res = await Axios.post(appendURL + `/api/product/`, data, headers);
        console.log("GetProductDetails res : ", res)
        return res.data
    }

    async GetOCRData(filename, vendorName) {
        var res = await Axios.post(appendURL + `/api/ocr`, { data: { filename, vendorName } });
        console.log("getocrdata", res);
        return res.data
    }

// get orc highlighted image

    async GetOCRHighlightedData(filename , vendorName,imageURLs) {
        console.log("filename", filename);
        console.log("venderName", vendorName);
        console.log("imageurls", imageURLs );
        var res = await Axios.post(appendURL + `/api/ocr-hight`, { data: { filename, vendorName ,imageURLs} });
        
        return res.data
    }

    async Getbase64Image(filename , vendorName,imageURLs,missingDataList) {
     
        var res = await Axios.post(appendURL + `/api/ocr-preview`, { data: { filename, vendorName ,imageURLs,missingDataList} });
      
        return res.data
    }

    async GetSavedInvoiceData(invoiceName, invoiceNo, date) {
        console.log("IN TESSERACT SERVICE");
        var res = await Axios.get(appendURL + "/api/invoice/getsaveinvoicedata/", { params: { invoiceName: invoiceName, invoiceNo: invoiceNo, date: date } });
        console.log(res);
        return res.data;
    }




    // Function of Handwritten functionality
    async GetHandwrittenSavedInvoiceData(invoiceName, invoiceNo, date) {
        console.log("IN TESSERACT SERVICE");
        var res = await Axios.get(appendURL + "/api/invoice/gethandwrittensaveinvoicedata/", { params: { invoiceName: invoiceName, invoiceNo: invoiceNo, date: date } });
        console.log(res);
        return res.data;
    }


    async getItemForHandwrittenInvoice(invoiceName) {
        console.log("getItemForHandwrittenInvoice : ", invoiceName)
        // console.log(data);
        let res = await Axios.get(appendURL + "/api/invoice/getitemhandwritten", {
            params: { invoiceName: invoiceName },
        });
        return res.data;
    }
    async UploadLinkDataFromCSV(payload) {
        console.log(payload);
        const res = await Axios.post(
            appendURL + "/api/invoice/upload-link-data-from-csv",
            payload
        );
        return res.data;
    }

    async getSampleFileUploadLinking() {
        console.log("hello world");
        const res = await Axios.post(appendURL + "/api/invoice/get-sample-file-uploadlinking");
        return res;
    }
    async convertDataToCSV(data) {
        console.log("data : ", data);
        const res = await Axios.post(appendURL + "/api/convert-data-to-csv", data);
        return res.data;
    }
    async saveStoreSetting(data){
        var res = await Axios.post(appendURL + `/api/store/setting`, data);
        return res.data  
    }

    async getStoreSetting(){
        const data = {}
        console.log("print from class baased get function")
        var res = await Axios.post(appendURL + `/api/store/get-setting`, data);
        return res.data
    }

}