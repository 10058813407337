import {
  FETCH_SETTING_SUCCESS,
  FETCH_SETTING_FAILURE,
  TOGGLE_MARGIN,
  SAVE_SETTING_SUCCESS,
  SAVE_SETTING_FAILURE,
} from "../action/actionStoreSetting";

// Retrieve settings from localStorage or set a default
const storedSetting =
  localStorage.getItem("Setting") != "undefined"
    ? JSON.parse(localStorage.getItem("Setting"))
    : { margin: true }; // Default value

localStorage.setItem("Setting", JSON.stringify({ margin: true }));

const initialState = {
  storeSetting: storedSetting,
  error: null,
};

const storeSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTING_SUCCESS:
      // localStorage.setItem("Setting", JSON.stringify(action.payload));
      return { ...state, storeSetting: action.payload };

    case FETCH_SETTING_FAILURE:
      return { ...state, error: action.payload };

    case SAVE_SETTING_SUCCESS:
      return { ...state, storeSetting: action.payload };

    case SAVE_SETTING_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default storeSettingReducer;
