import React, { useState, useEffect } from 'react';
import './operations.css';
import Axios from '../../services/axios';
import { useSelector } from 'react-redux';

// Reusable SyncOperation component
const SyncOperation = ({ operationName, syncStatus, setSyncStatus, statusMessage, setStatusMessage, messageStyle, checkSyncStatus, startSync }) => {
    const operationKey = operationName.toLowerCase(); 
    console.log("operationKey",operationKey)
    return (
        <div className="operation-item">
            <span>Sync {operationName}</span>

            <span className="status-icon">
                {syncStatus[operationKey] === 'processing' && (
                    <>
                        <div className="spinner"></div>
                        {statusMessage && (
                            <span className="status-message" style={{ ...messageStyle, marginRight: '10px' }}>
                                {statusMessage}
                            </span>
                        )}
                    </>
                )}
                {syncStatus[operationKey] === 'success' && (
                    <>
                        <span className="done-icon">✅</span>
                        {statusMessage && (
                            <span className="status-message" style={{ ...messageStyle, marginLeft: '10px' }}>
                                {statusMessage}
                            </span>
                        )}
                    </>
                )}
            </span>

            <button
                className="start-button"
                onClick={() =>
                    syncStatus[`${operationKey}JobId`] ? checkSyncStatus(operationKey) : startSync(operationKey)
                }
            >
                {syncStatus[`${operationKey}JobId`] ? "Status" : "Start"}
            </button>

        </div>
    );
};

const Operations = () => {
    const [syncStatus, setSyncStatus] = useState({
        centraldb: 'idle',
        allproducts: 'idle',
        centraldbJobId: '',
        allproductsJobId: '',
    });
    const [statusMessage, setStatusMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState({});

    useEffect(() => {
        const storedCentralDBJobId = localStorage.getItem('centraldbJobId');
        const storedAllProductsJobId = localStorage.getItem('allproductsJobId');

        if (storedCentralDBJobId) {
            setSyncStatus((prevStatus) => ({
                ...prevStatus,
                centraldb: 'processing',
                centraldbJobId: storedCentralDBJobId,
            }));
        }

        if (storedAllProductsJobId) {
            setSyncStatus((prevStatus) => ({
                ...prevStatus,
                allproducts: 'processing',
                allproductsJobId: storedAllProductsJobId,
            }));
        }
    }, []);

    // const startSync = async (operation) => {
    //     try {
    //         let response;
    //         if (operation === 'centraldb') {
    //             response = await Axios.get("/syncCentralDb");
    //             console.log("centralDB", response)
    //         } else if (operation === 'allproducts') {
    //             response = await Axios.post("/syncAllProducts");
    //             console.log("centralDB", response)

    //         }

    //         const jobId = response?.data?.jobId;
    //         const updatedSyncStatus = { ...syncStatus, [operation]: 'processing', [`${operation}JobId`]: jobId };

    //         setSyncStatus(updatedSyncStatus);
    //         localStorage.setItem(`${operation}JobId`, jobId);
    //     } catch (err) {
    //         console.log(`Error setting up the request for ${operation}:`, err.message);
    //     }
    // };



    const startSync = async (operation) => {
        console.log(`Starting sync for ${operation}`);
     const invoiceList = JSON.parse(localStorage.getItem("invoiceList"));

        try {
            let response;
            if (operation === 'centraldb') {
                response = await Axios.get("/api/syncCentralDb");
                console.log("centralDB response", response);
            } else if (operation === 'allproducts') {
                response = await Axios.post("/api/syncAllProducts",invoiceList);
                console.log("allProducts response", response);
            }
            const jobId = response?.data?.jobId;
            console.log("jobID",jobId)
            const updatedSyncStatus = { ...syncStatus, [operation]: 'processing', [`${operation}JobId`]: jobId };

            setSyncStatus(updatedSyncStatus);
            localStorage.setItem(`${operation}JobId`, jobId);
        } catch (err) {
            console.log(`Error setting up the request for ${operation}:`, err.message);
        }
    };

    const checkSyncStatus = async (operation) => {
        const jobId = localStorage.getItem(`${operation}JobId`);
        if (!jobId) return;

        try {
            let response;
            if (operation === 'centraldb') {
                response = await Axios.get(`/api/syncCentralDb`, { params: { jobId } });
            } else if (operation === 'allproducts') {
                response = await Axios.get(`/api/syncAllProducts`, { params: { jobId } });
            }

            if (response.data.status === 'completed') {
                setMessageStyle({ backgroundColor: 'green', color: 'white' });
                setStatusMessage('Process completed successfully!');
                localStorage.removeItem(`${operation}JobId`);

                setTimeout(() => {
                    setStatusMessage('');
                }, 1000);

                setSyncStatus((prevStatus) => ({
                    ...prevStatus,
                    [operation]: 'idle',
                    [`${operation}JobId`]: '',
                }));
            } else if (response.data.status === 'pending') {
                setMessageStyle({ backgroundColor: 'orange', color: 'black' });
                setStatusMessage('Process is already running!');
                setTimeout(() => {
                    setStatusMessage('');
                }, 1000);
            }

        } catch (err) {
            console.log(`Error fetching sync status for ${operation}:`, err.message);
        }
    };

    return (
        <>
            <div className='OperationsWrapper'>
                <div className='operationTitle'>
                    <h2 className="operations-heading">Operations</h2>
                </div>

                <div className="operations-container">
                    {/* Reusable SyncOperation component for Central DB */}
                    <SyncOperation
                        operationName="CentralDB"
                        syncStatus={syncStatus}
                        setSyncStatus={setSyncStatus}
                        statusMessage={statusMessage}
                        setStatusMessage={setStatusMessage}
                        messageStyle={messageStyle}
                        checkSyncStatus={checkSyncStatus}
                        startSync={startSync}
                    />

                    {/* Reusable SyncOperation component for All Products */}
                    <SyncOperation
                        operationName="AllProducts"
                        syncStatus={syncStatus}
                        setSyncStatus={setSyncStatus}
                        statusMessage={statusMessage}
                        setStatusMessage={setStatusMessage}
                        messageStyle={messageStyle}
                        checkSyncStatus={checkSyncStatus}
                        startSync={startSync}
                    />
                </div>
            </div>
        </>
    );
};

export default Operations;
