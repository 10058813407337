import React, { useEffect, Suspense, useRef } from "react";
import Layout from './components/Layout/Layout';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from "./UI/Spinner/Spinner";
import { checkAuthentication } from "./store/action/actionAuth";
// import { initWebSocket, setOnMessageHandler } from "./webSocket";
import Custom from "./components/settings/Settings"
import axios from "axios";
import { fetchStoreSetting } from "./store/action/actionStoreSetting";

const Auth = React.lazy(() => import("./containers/Auth/Auth"));
const Logout = React.lazy(() => import("./containers/Auth/Logout/Logout"));
const Invoice = React.lazy(() => import("./components/Invoice/Invoice"));
const Queue = React.lazy(() => import("./components/Queue/Queue"));
const Pos = React.lazy(() => import("./components/Pos/Pos"));
const SaveInvoiceData = React.lazy(() => import("./components/SaveInvoiceData/SaveInvoiceData"));
const Inventory = React.lazy(() => import("./components/Inventory/Inventory"));
const HandWrittenInvoice = React.lazy(() => import("./components/HandWrittenInvoice/HandWrittenInvoice"));
const Monitor = React.lazy(() => import("./components/Monitor/Monitor"));
const MergeAllProducts = React.lazy(() => import("./components/Mergeallproducts/MergeAllProducts"));
const InventoryData = React.lazy(() => import("./components/inventoryData/InventoryData"));
const StockUpdate = React.lazy(() => import("./components/stockUpdate/StockUpdate"));
const PrepareData = React.lazy(() => import("./components/PrepareData/PrepareData"));
const RegexData = React.lazy(() => import("./components/regexData/RegexData"));
const UserAccess = React.lazy(() => import("./components/UserAccess/UserAccess"));
const UploadLinking = React.lazy(() => import("./components/UploadLinking/UploadLinking"));

const App = () => {
  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const dispatch = useDispatch();
  const ws = useRef(null);

  useEffect(() => {
    dispatch(checkAuthentication());
  }, [dispatch]);

 

  // useEffect(() => {
  //   console.log("aga-->");
  //   const ws = initWebSocket();
    
  //   setOnMessageHandler((event) => {
  //     console.log("eeNew-->", event, event.data);
  //     localStorage.getItem("hicksvilleString") && hicksvilleFunc(event.data);
  //   });
  // }, [localStorage.getItem("hicksvilleString")]);




const hicksvilleFunc = (hicksData) => {
    let hicksvilleString = localStorage.getItem("hicksvilleString");
    const splitNames = hicksvilleString.split("\n");

    // console.log("hicks-->", splitNames);

    // const barcodeToReplace = '011433155632';
    const barcodeToReplace = hicksData.split('@@@')[0];

    // const barcodeToFilter = '011433155632';
    const barcodeToFilter = hicksData.split('@@@')[0];

// Filter the array to get only the item with the matching barcode
const filteredData = splitNames.filter(item => {
    const barcode = item.split('@@@')[0];
    return barcode === barcodeToFilter;
});

// console.log("fil-->", filteredData);

    const updatedData = splitNames.map(item => {
      const barcode = item.split('@@@')[0];
      if (barcode === barcodeToReplace) {
          return hicksData;
      }
      return item;
  });
  
  // console.log("dataaa-->", updatedData);

  const filteredDataNew = updatedData.filter(item => {
    const barcode = item.split('@@@')[0];
    return barcode === barcodeToFilter;
});

// console.log("fil-->1", filteredDataNew, updatedData.join("\n"));

localStorage.setItem("hicksvilleString", updatedData.join("\n"));

  }

  const showCondition = (localStorage.getItem("store") && JSON.parse(localStorage.getItem("invoiceList")) && JSON.parse(localStorage.getItem("optionData")) && localStorage.getItem("posApi") && localStorage.getItem("posAccessToken")) ? true : false;

  let routes = (
    <Switch>
      <Route path="/" exact component={Auth} />
      <Redirect to="/" />
    </Switch>
  );


  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/invoice" component={Invoice} />
        {/* <Route path="/auth" component={Auth} /> */}
        {/* <Route path="/orders" component={Orders} /> */}
        <Route path="/logout" component={Logout} />
        <Route path="/queue" component={Queue} />
        <Route path="/handwritten" component={HandWrittenInvoice} />
        <Route path="/invoicedata" component={SaveInvoiceData} />
        <Route path="/inventory" component={Inventory} />
        <Route path="/monitor" component={Monitor} />
        <Route path="/mergeallproducts" component={MergeAllProducts} />
        <Route path="/inventorydata" component={InventoryData} />
        <Route path="/stockupdate" component={StockUpdate} />
        <Route path="/prepareData" component={PrepareData} />
        {/* <Route path="/userAccess" component={UserAccess} /> */}
        {/* <Route path="/regexdata" component={RegexData} /> */}

        <Route path="/setting" component={Custom} />
        <Route path="/uploadlinking" component={UploadLinking} />

        {!showCondition ? <Redirect to="/prepareData" /> : <Redirect to="/invoice" />}
      </Switch>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <div>
        {isAuthenticated ? (
              <Layout>
                {routes}
              </Layout>
            ) : (
              routes
            )}
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;













// import React, { Component, Suspense } from "react";
// import Layout from './components/Layout/Layout';
// import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import Spinner from "./UI/Spinner/Spinner";
// import { checkAuthentication } from "./store/action/actionAuth";

// const Auth = React.lazy(() => import("./containers/Auth/Auth"));
// const Logout = React.lazy(() => import("./containers/Auth/Logout/Logout"));
// const Invoice = React.lazy(() => import("./components/Invoice/Invoice"));
// const Queue = React.lazy(() => import("./components/Queue/Queue"));
// const Pos = React.lazy(() => import("./components/Pos/Pos"));
// const SaveInvoiceData = React.lazy(() => import("./components/SaveInvoiceData/SaveInvoiceData"));
// const Inventory = React.lazy(() => import("./components/Inventory/Inventory"));
// const HandWrittenInvoice = React.lazy(() => import("./components/HandWrittenInvoice/HandWrittenInvoice"));
// const Monitor = React.lazy(() => import("./components/Monitor/Monitor"));
// const MergeAllProducts = React.lazy(() => import("./components/Mergeallproducts/MergeAllProducts"));
// const InventoryData = React.lazy(() => import("./components/inventoryData/InventoryData"));
// const StockUpdate = React.lazy(() => import("./components/stockUpdate/StockUpdate"));
// const PrepareData = React.lazy(() => import("./components/PrepareData/PrepareData"));
// const RegexData = React.lazy(() => import("./components/regexData/RegexData"));

// class App extends Component {
//   componentDidMount() {
//     this.props.checkAuthentication();
//   }
//   render() {

//     let showCondition = (localStorage.getItem("store") && JSON.parse(localStorage.getItem("invoiceList")) && JSON.parse(localStorage.getItem("optionData")) && localStorage.getItem("posApi") && localStorage.getItem("posAccessToken")) ? true : false;

//     let routes = (
//       <Switch>
//         <Route path="/" exact component={Auth} />
//         <Redirect to="/" />
//       </Switch>
//     );
//     if (this.props.isAuthenticated) {
//       routes = (
//         <Switch>
//           <Route path="/invoice" component={Invoice} />
//           {/* <Route path="/auth" component={Auth} /> */}
//           {/* <Route path="/orders" component={Orders} /> */}
//           <Route path="/logout" component={Logout} />
//           <Route path="/queue" component={Queue}/>
//           <Route path="/handwritten" component={HandWrittenInvoice} />
//           {/* <Route path="/pos" component={Pos}/> */}
//           <Route path="/invoicedata" component={SaveInvoiceData} />
//           <Route path="/inventory" component={Inventory}/>
//           <Route path="/monitor" component={Monitor}/>
//           <Route path="/mergeallproducts" component={MergeAllProducts}/>
//           <Route path="/inventorydata" component={InventoryData}/>
//           <Route path="/stockupdate" component={StockUpdate}/>
//           <Route path="/prepareData" component={PrepareData} />
         
//           {/* <Redirect to="/invoice" /> */}
//           {/* <Redirect to="/prepareData" /> */}

//           {!showCondition ? <Redirect to="/prepareData" /> : <Redirect to="/invoice" />}
//         </Switch>
//       );
//     }
//     return (
//       <Suspense fallback = {<Spinner />}>
//         <BrowserRouter>
//             <div>
//             <Layout>
//               {routes}
//             </Layout>
//             </div>
//         </BrowserRouter>  
//       </Suspense>
//     )
//   }
// }

// const mapStateToProps = state => {
//     return {
//         isAuthenticated: state.auth.token !== null
//     };
// };

// const mapDispatchToProps = dispatch => {
//     return {
//        checkAuthentication: () => dispatch(checkAuthentication())
//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);
