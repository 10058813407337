import React, { useEffect, useState } from 'react'

import styles from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import CwIcon from "../../../Images/CwIcon.png"

import { ToastContainer, toast } from "react-toastify";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import firebase from "../../../firebase";
import { useLocation } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../UI/Button";
import { authLogout } from '../../../store/action/actionAuth';
import { Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { InventoryService } from '../../../services/InventoryService';
import UseDebounce from '../../UseDebounce';
const db = firebase.firestore();


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      position: 'fixed',
      bottom: 0,
    },
  },

}));
const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;

const loadercss = {
  position: "absolute",
  marginTop: "46rem",
  marginLeft: "-2rem",
  background: "#ffffffc7",
  padding: "24rem",
  zIndex: 1,
  width: "101vw",
};

const NavigationItems = (props) => {
  const { selectedStore,loaderFun } = props;
  console.log("selectedStore11", selectedStore)
  const [user, setUser] = useState(props.isAuth);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [workdone, setWorkdone] = useState();

  // const credentialsData = useSelector((state) => state.credentialsData.credentialsLoaderData);
  const userEmail = useSelector((state) => state.userDetails.userEmail);
  const [isEmail, setIsEmail] = useState(null);
  const [isCloseButton, setIsCloseButton] = useState(false);
  const [authUser, setAuthUser] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isActive = location.pathname === "/setting";
  useEffect(() => {
    setUser(props.isAuth);
    // console.log("User", user)
  }, [user]);

  useEffect(() => {
    userEmail !== "" && checkUserExists();
  }, [userEmail]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }



  const classes = useStyles();

  let currentTime = new Date().toLocaleTimeString();

  const handleClose = (event, reason) => {
    db.collection("syncProductsNew")
      // .doc(credentialsData.ICMS_NAME)
      .doc(localStorage.getItem("store"))
      .set({ loader: false, error: false, workdone: false, email: userEmail, time: currentTime, closeButton: false })
      .then(() => {
        console.log("Document successfully closed!");
      })
  };

  const handleClosWorkdone = (event, reason) => {
    db.collection("syncProductsNew")
      .doc(localStorage.getItem("store"))
      .set({ loader: false, error: false, workdone: false, email: userEmail, time: currentTime, closeButton: false })
      .then(() => {
        console.log("Document successfully written!");
      })
  };


  const debouce = function (fn, d) {
    console.log("debouce : ")
    let timer;
    return function (event, value, index) {
      // let context = this,
      // args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(event, value, index);
      }, d);
    };
  };

  const loaderFun_debounce = debouce(loaderFun, 500);
  

  // db.collection("syncProductsNew")
  // Object.keys(credentialsData).length > 0 && db.collection("syncProductsNew")
  localStorage.getItem("store") && localStorage.getItem("store").length > 0 && db.collection("syncProductsNew")
    .doc(localStorage.getItem("store"))
    .onSnapshot(async (snapshot) => {
      console.log("snapshot : ", snapshot);
      console.log("snapshot data: ", snapshot.data());
      let data = snapshot.data();

      setLoader(data?.loader);
      if (data?.workdone) {
        console.log("myloader", data.loader)
        loaderFun_debounce()
        // loaderFun()
      }

      setError(data?.error)
      setWorkdone(data?.workdone)
      // if(data.error!==""){
      //   setError(true);
      // }

      setIsEmail(data?.email);
      setIsCloseButton(data?.closeButton);

    });


  console.log("error state : ", error)




  const showCondition = (localStorage.getItem("store") && JSON.parse(localStorage.getItem("invoiceList")) && JSON.parse(localStorage.getItem("optionData")) && localStorage.getItem("posApi") && localStorage.getItem("posAccessToken")) ? true : false;

  const checkUserExists = async () => {
    try {
      const doc = await db.collection("store_access").doc(userEmail).get();
      if (doc.exists) {
        let userList = doc.data();
        console.log("doc-->", userList.auth_users, userEmail, doc);
        if (userList?.auth_user === "Yes") {
          setAuthUser(true);
          console.log("authUser-->", authUser);
        }
      } else {
        console.log("No such user!");
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }

  };

  return (
    <>
      <ul className={styles.NavigationItems}>
        <a href="/">
          <img className={styles.logo} src={CwIcon} alt="logo" />
        </a>
        {props.isAuth ? (
          <>
            {/* {showCondition && <NavigationItem link="/handwritten">Hand Written Invoices</NavigationItem>} */}
            {showCondition && (<NavigationItem link="/invoicedata"> Invoice Data</NavigationItem>)}
            {showCondition && <NavigationItem link="/invoice">Scan Invoice</NavigationItem>}
            {/* {showCondition && <NavigationItem link="/mergeallproducts">Merge Products</NavigationItem>} */}
            {showCondition && <NavigationItem link="/inventorydata">Inventory Data</NavigationItem>}
            {/* {showCondition && <NavigationItem link="/stockupdate">Stock Update</NavigationItem>} */}
            {showCondition && <NavigationItem link="/uploadlinking">Upload Linking</NavigationItem>}

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '20px' }}>

              <NavigationItem link="/logout">
                <Tooltip title={"logout"}>
                  <ExitToAppIcon style={{ fontSize: "25px", color: "#fff", cursor: "pointer", position: "absolute", right: "55", zIndex: "2000", top: "20" }} />
                </Tooltip>
              </NavigationItem>

              {authUser && (
                <NavigationItem link="/setting" style={{ position: "relative", border: "none" }}>
                  <Tooltip title="setting" placement="top">
                    <SettingsIcon
                      style={{
                        fontSize: "25px",
                        color: isActive ? "#007bff" : "#fff",
                        cursor: "pointer",
                        position: "absolute",
                        right: "5px",
                        zIndex: 2000,
                        top: "20px"
                      }}
                    />
                  </Tooltip>
                </NavigationItem>
              )}

            </div>


            {loader ? (
              <div style={loadercss}>
                <MoonLoader
                  color={"#42a5f5"}
                  loading={true}
                  css={override}
                  size={100}
                />
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <h4>Products Syncing...</h4>
                  <p style={{ backgroundColor: "white", color: "grey" }}>
                    {`Started by ${isEmail} at ${currentTime}`}
                  </p>

                  <Button
                    text={"Close"}
                    color="btn btn-info"
                    type="submit"
                    onClick={() => handleClose()}
                    style={{ width: 120, color: "white" }}
                  />

                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : null}
      </ul>
      <div className={classes.root} >
        {/* <Snackbar open={error} autoHideDuration={500} onClose={handleClose} > */}
        <Snackbar open={error} autoHideDuration={10000} onClose={handleClose} >
          {/* <Alert onClose={handleClose} severity="error"> */}
          <Alert severity="error">
            Some Error Occerred ! Not getting updated hicksvilleString.
          </Alert>
        </Snackbar>
        {/* <Snackbar open={workdone} autoHideDuration={500} onClose={handleClose} > */}
        <Snackbar open={workdone} autoHideDuration={10000} onClose={handleClose} >
          {/* <Alert onClose={handleClosWorkdone} severity="success"> */}
          <Alert severity="success">
            Products Sync Successfully. Please wait 10 seconds for getting the updated hicksvilleString.
          </Alert>
        </Snackbar>
      </div>

      <ToastContainer limit={1} />
    </>
  );
};

export default NavigationItems;