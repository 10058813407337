import React, { useState } from 'react';
import './settings.css'; 
import RegexComponent from '../regexData/RegexData';
import CreateICMS from './CreateIcms';  
import Operations from './Operations';  
import MarginList from './MarginList';
import WelcomeToSettings from './WelocomePage';
import UserAccess from "../UserAccess/UserAccess"
import StoreSettingPage from './StoreSetting';

const CreateStore = () => {
  const [activeComponent, setActiveComponent] = useState('aboutSetting');
  const storeName = localStorage.getItem('store')

  return (
    <div className="layout">
      <div className="sidebar" style={{ marginTop: "70px" }}>
        <div
          className={`sidebar-item ${activeComponent === 'regex' ? 'active' : ''}`}
          onClick={() => setActiveComponent('regex')}
        >
          Create Regex
        </div>
        <div
          className={`sidebar-item ${activeComponent === 'database' ? 'active' : ''}`}
          onClick={() => setActiveComponent('database')}
        >
          Create ICMS
        </div>
        <div
          className={`sidebar-item ${activeComponent === 'operations' ? 'active' : ''}`}
          onClick={() => setActiveComponent('operations')}
        >
          Operations
        </div>
        <div
          className={`sidebar-item ${activeComponent === 'marginlist' ? 'active' : ''}`}
          onClick={() => setActiveComponent('marginlist')}
        >
          Margin List
        </div>
        <div
          className={`sidebar-item ${activeComponent === 'userAccess' ? 'active' : ''}`}
          onClick={() => setActiveComponent('userAccess')}
        >
          User Access
        </div>
        <div
          className={`sidebar-item ${activeComponent === 'aboutSetting' ? 'active' : ''}`}
          onClick={() => setActiveComponent('aboutSetting')}
        >
         About Settings
        </div>
{storeName &&<div
          className={`sidebar-item ${activeComponent === 'storeSetting' ? 'active' : ''}`}
          onClick={() => setActiveComponent('storeSetting')}
        >
         Store Settings
        </div>}
        
      </div>

      <div className="content">
        {activeComponent === 'regex' && <div ><RegexComponent /></div>}
        {activeComponent === 'database' && <div className="centered"><CreateICMS /></div>}
        {activeComponent === 'operations' && <div className="centered"><Operations /></div>}
        {activeComponent === 'marginlist' && <div className="centered"><MarginList /></div>}
        {activeComponent === 'aboutSetting' && <div className="centered"><WelcomeToSettings /></div>}
        {activeComponent === 'userAccess' && <div className="centered"><UserAccess /></div>}

        {activeComponent=== 'storeSetting' && <div className="centered"><StoreSettingPage /></div>}
      </div>
    </div>
  );
};

export default CreateStore;
