
import React from 'react';
import './WelcomeToSettings.css';

const WelcomeToSettings = () => {
    return (

        <div className='aboutWrapper'>
            <div className='aboutTitle'>
                <h2 className="aboutHeading">About Settings</h2>
            </div>
            <div className="welcome-settings">
                <h2>Welcome to the Settings Page</h2>
                <p>Here you can find various settings related to our application. Below you can explore the options available:</p>
                <ul>
                    <li>Create Regex: Manage and create regex patterns.</li>
                    <li>Create Database: Add and manage database entries.</li>
                    <li>Operations: Perform various operations.</li>
                    <li>Margin List: View and manage margin lists.</li>
                </ul>
            </div>
        </div>

    );
};

export default WelcomeToSettings;

