import { useState, useEffect } from 'react';

const  UseDebounce = (value, delay) =>  {
   
    const [debouncedValue, setDebouncedValue] = useState(value);
    console.log("hello debouce==>",debouncedValue)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export default UseDebounce;
