
const initialState = {
    myOptionData: {},
    myInvoiceData:{}
};

const regexDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "option_data":
            return { ...state, myOptionData: action.data }
            case "invoice_data":
            return { ...state, myInvoiceData: action.data }
            case 'UPDATE_VENDOR_DATA':
                return { ...state, myOptionData: action.data };
        default:
            return state
    }
}


export default regexDataReducer;




