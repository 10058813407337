const initialState = {
    credentialsLoaderData: {},
    hicksvilleData: {}
};

const credentialsDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CRED_LOADER_DATA":
            return { ...state, credentialsLoaderData: action.data }
        case "HICKSVILLE_DATA":
            return { ...state, hicksvilleData: action.data }
        default:
            return state
    }
}


export default credentialsDataReducer;