import firebase from 'firebase'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDZFnjldUba4d8Xd6ElHEH7dwjekHHocHQ",
  authDomain: "spicebazar-cali.firebaseapp.com",
  projectId: "spicebazar-cali",
  storageBucket: "spicebazar-cali.appspot.com",
  messagingSenderId: "923498350863",
  appId: "1:923498350863:web:7ba743522d50d36e4c967a",
  measurementId: "G-FZGYL8VZD4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const auth = firebase.auth();
export default firebase
