const initialState = {
    InventoryData: []
};

const inventoryDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "INVENTORY_DATA":
            return { ...state, InventoryData: action.data }
        default:
            return state
    }
}


export default inventoryDataReducer;