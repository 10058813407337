import { TesseractService } from "../../services/TesseractService";

const tesseractService = new TesseractService();

// Action types
export const FETCH_SETTING_SUCCESS = "FETCH_SETTING_SUCCESS";
export const FETCH_SETTING_FAILURE = "FETCH_SETTING_FAILURE";
export const TOGGLE_MARGIN = "TOGGLE_MARGIN";
export const SAVE_SETTING_SUCCESS = "SAVE_SETTING_SUCCESS";
export const SAVE_SETTING_FAILURE = "SAVE_SETTING_FAILURE";

// Fetch store settings from the API
export const fetchStoreSetting = () => async (dispatch) => {
  try {
    const response = await tesseractService.getStoreSetting();
    // console.log(" Respones", response);
    const settingData = response;
    // console.log("in sction line number 17");
    // console.log(settingData);
    // Store the full object in localStorage
    localStorage.setItem("Setting", JSON.stringify(settingData));

    dispatch({ type: FETCH_SETTING_SUCCESS, payload: settingData });
  } catch (error) {
    dispatch({ type: FETCH_SETTING_FAILURE, payload: error.message });
  }
};

// Toggle margin setting
export const toggleMargin = () => ({
  type: TOGGLE_MARGIN,
});

// Save store settings to the API
export const saveStoreSetting = (storeSetting) => async (dispatch) => {
  try {
    await tesseractService.saveStoreSetting(storeSetting);
    localStorage.setItem("Setting", JSON.stringify(storeSetting));
    console.log("line numerb 39");
    console.log(storeSetting);
    dispatch({ type: SAVE_SETTING_SUCCESS, payload: storeSetting });
  } catch (error) {
    console.log("Error");
    dispatch({ type: SAVE_SETTING_FAILURE, payload: error.message });
  }
};
