import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStoreSetting,
  toggleMargin,
  saveStoreSetting,
} from "../../store/action/actionStoreSetting.js";
import "./storeSetting.css";
// code added by Tushar
export default function StoreSettingPage() {
  const dispatch = useDispatch();
  const storeSetting = useSelector((state) => state.storeSetting.storeSetting);

  useEffect(() => { 
    dispatch(fetchStoreSetting());
    setLocalMargin(storeSetting.margin)  
   } , [dispatch]);

  const [localMargin, setLocalMargin] = useState(false);

console.log("line number 20")
  // Handle save action
  const handleSaveSetting = async () => {
    console.log(localMargin);
    console.log("line number 27");
    if (window.confirm("Do you really want to save?")) {
      dispatch(saveStoreSetting({ ...storeSetting, margin: localMargin }));
    }
  };

  return (
    <div className="aboutWrapper">
      <div className="aboutTitle">
        <h2 className="aboutHeading">Store Settings</h2>
      </div>
      <div className="welcome-settings">
        <h2>Price Setting</h2>
        <div className="priceSettingContainer">
          <div className="markupContainer">
            <label className="PriceMargin">Markup</label>
            <input
              type="checkbox"
              checked={!localMargin}
              onChange={() => setLocalMargin(false)}
            />
          </div>
          <div className="marginContainer">
            <label className="PriceMargin">Margin</label>
            <input
              type="checkbox"
              checked={localMargin}
              onChange={() => setLocalMargin(true)}
            />
          </div>
        </div>
        <button className="cancel-button">Cancel</button>
        <button className="save-button" onClick={handleSaveSetting}>
          Save
        </button>
      </div>
    </div>
  );
}
